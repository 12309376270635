import React from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from "@chakra-ui/react";

const htmlDoctypeRegex = /^<!DOCTYPE\s+html>/i;

const CancelVisitDialog = ({ alertDialogData, isOpen, onNegation, onAffirmation }) => {
  const cancelRef = React.useRef();
  if (!alertDialogData.affirmButtonColor) alertDialogData.affirmButtonColor = "red";
  if (!alertDialogData.headerBgColor) alertDialogData.headerBgColor = "teal.700";

  // Determine if body is HTML or plain text
  const isHtml = htmlDoctypeRegex.test(alertDialogData.body);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onNegation} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" bg={alertDialogData.headerBgColor}>
            {alertDialogData.header}
          </AlertDialogHeader>
          <AlertDialogBody mt={4}>
            {isHtml ? <Text dangerouslySetInnerHTML={{ __html: alertDialogData.body }} /> : alertDialogData.body}
          </AlertDialogBody>
          <AlertDialogFooter bg="white">
            {onNegation && (
              <Button ref={cancelRef} onClick={onNegation}>
                {alertDialogData.negationButtonText}
              </Button>
            )}

            <Button colorScheme={alertDialogData.affirmButtonColor} onClick={onAffirmation} ml={3}>
              {alertDialogData.affirmationButtonText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CancelVisitDialog;


//isHtml ? <Text dangerouslySetInnerHTML={{ __html: alertDialogData.body }} /> :