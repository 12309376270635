// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// import avatar1 from "assets/img/avatars/avatar1.png";
// import avatar2 from "assets/img/avatars/avatar2.png";
// import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
// import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
import { CgProfile } from "react-icons/cg";
import { CiSettings } from "react-icons/ci";

// Custom Components
import { ItemContent } from "./ItemContent";
// import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import { MdLogout } from "react-icons/md";
import { useLogout } from "hooks/useLogout";
import SidebarResponsive from "./SidebarResponsive";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  const { logout } = useLogout();
  const navigate = useNavigate();

  // Chakra Color Mode
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <Button
        ms="0px"
        px="0px"
        me={{ sm: "2px", md: "16px" }}
        color={navbarIcon}
        variant="transparent-with-icon"
        leftIcon={<MdLogout />}
        onClick={handleLogout}
      >
        <Text display={{ sm: "none", md: "flex" }}>Logout</Text>
      </Button>
      <SidebarResponsive />
    </Flex>
  );
}
