import React from "react";
import CreateReportForm from "./CreateReportForm"; // Adjust the path as needed
import api from "../api";
import { Box, HStack, Heading } from "@chakra-ui/react";

const CreateReportPage = () => {
  const handleSubmit = async (values) => {
    console.log("Sending report :: ", values)
    try {
      const response = await api.post("/api/reports", values);

      
      console.log("Report created:", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box mt="90px">
      <HStack justify="center" mb="20px">
        <Heading>Create Report</Heading>
      </HStack>
      <CreateReportForm onSubmit={handleSubmit} />
    </Box>
  );
};

export default CreateReportPage;
