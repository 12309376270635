import React, { useRef } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Switch,
  Text,
  useColorModeValue,
  Link as ChakraLink,
  LinkProps,
  useToast,
  FormErrorMessage,
  Icon,
  Image,
  Divider,
} from "@chakra-ui/react";
// Assets
import signInImage from "../../assets/img/vKutumbProcess.png";
import {
  Navigate,
  Link as ReactRouterLink,
  useNavigate,
} from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLogin } from "hooks/useLogin";
import ChakraInput from "components/ChInput";
import RecaptchaComponent from "components/RecaptchaComponent";
import logo from "../../assets/img/logo.jpg";
import Header from "../../pages/Home/Header";
import { useAuthContext } from "../../hooks/useAuthContext";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6, "Too Short!").required("Required"),
});

function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");

  const { login, error, isLoading } = useLogin();
  const toast = useToast();
  const recaptchaRef = useRef(null);

  async function handleSubmit(values, submitProps) {
    var loginError = await login(values);
    if (loginError) {
      toast({
        title: "Login failed.",
        description: loginError.response.data.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    recaptchaRef.current.reset();
  }

  return (
    <>
      <Header />
      <Flex position="relative" mb="40px">
        <Flex
          h={{ sm: "initial", md: "75vh", lg: "100vh" }}
          w="100%"
          maxW="1044px"
          mx="auto"
          justifyContent="space-between"
          mb="30px"
          pt={{ sm: "100px", md: "0px" }}
        >
          <Flex
            alignItems="center"
            justifyContent="start"
            style={{ userSelect: "none" }}
            w={{ base: "100%", md: "50%", lg: "42%" }}
          >
            <Flex
              direction="column"
              w="100%"
              background="transparent"
              p="48px"
              mt={{ md: "100px", lg: "20px" }}
            >
              <Heading color={titleColor} fontSize="32px" mb="10px">
                Welcome Back
              </Heading>
              <Text
                mb="36px"
                ms="4px"
                color={textColor}
                fontWeight="bold"
                fontSize="14px"
              >
                Enter your email and password to sign in
              </Text>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={LoginSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    <FormControl
                      isInvalid={errors.email && touched.email}
                      mb="24px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Email
                      </FormLabel>
                      <Field
                        as={Input}
                        id="email"
                        name="email"
                        type="email"
                        borderRadius="15px"
                        fontSize="sm"
                        placeholder="Your email adress"
                        size="lg"
                      />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={errors.password && touched.password}
                      mb="36px"
                    >
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Password
                      </FormLabel>
                      <Field
                        as={Input}
                        id="password"
                        name="password"
                        borderRadius="15px"
                        fontSize="sm"
                        type="password"
                        placeholder="Your password"
                        size="lg"
                      />

                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    <FormControl display="flex" alignItems="center" mb="14px">
                      <Switch
                        id="remember-login"
                        colorScheme="teal"
                        me="10px"
                      />
                      <FormLabel
                        htmlFor="remember-login"
                        mb="0"
                        ms="1"
                        fontSize={"sm"}
                        fontWeight="normal"
                      >
                        Remember me for 30 days
                      </FormLabel>
                    </FormControl>
                    <RecaptchaComponent
                      ref={recaptchaRef}
                      onChange={(val) => {
                        setFieldValue("token", val);
                      }}
                    />
                    <Button
                      fontSize="10px"
                      type="submit"
                      bg="teal.300"
                      w="100%"
                      h="45"
                      mb="10px"
                      color="white"
                      mt="20px"
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}
                      isDisabled={!values.token}
                    >
                      SIGN IN
                    </Button>
                  </Form>
                )}
              </Formik>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
              >
                <ChakraLink
                  color={"teal.400"}
                  as={ReactRouterLink}
                  ms="5px"
                  // fontWeight="bold"
                  to="/forgot-password"
                  fontSize={"sm"}
                >
                  Forgot password?
                </ChakraLink>
              </Flex>
              <Divider mb="30px" mt="30px"/>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColor} fontWeight="medium">
                  Don't have an account?
                  <ChakraLink
                    color={titleColor}
                    as={ReactRouterLink}
                    ms="5px"
                    fontWeight="bold"
                    to="/signup"
                  >
                    Sign Up
                  </ChakraLink>
                </Text>
              </Flex>
              
            </Flex>
          </Flex>
          <Box
            display={{ base: "none", md: "block" }}
            overflowX="hidden"
            h="100%"
            w="50vw"
            position="absolute"
            right="0px"
          >
            <Box
              bgImage={signInImage}
              w="100%"
              h="100%"
              bgSize="contain"
              bgRepeat={"no-repeat"}
              bgPosition="right"
              // position="absolute"
              borderBottomLeftRadius="20px"
            ></Box>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default SignIn;
