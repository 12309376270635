import React from "react";
import { Box, Container, Text, Heading, List, ListItem, Link, Stack, Button, Divider } from "@chakra-ui/react";
import Header from "./Header";

const TermsAndConditions = () => {
  return (
    <>
      <Header />

      <Container maxW="container.md" py={8}>
        <Box bg="white" p={6} borderRadius="md" boxShadow="lg">
          <Heading as="h1" size="xl" mb={6}>
            vKutumb Medicine Reminder App: Terms and Conditions & Privacy Policy
          </Heading>
          <Text fontSize="sm" color="gray.500" mb={6}>
            Last Updated: Nov 26, 2024
          </Text>

          <Text mb={4}>
            Welcome to the vKutumb Medicine Reminder App! By downloading, accessing, or using our app, you agree to
            comply with and be bound by the following Terms and Conditions (T&C), which also include our Privacy Policy.
            If you do not agree with these terms, please do not use the app.
          </Text>

          <Stack spacing={6} mb={6}>
            <Box>
              <Heading as="h3" size="md" mb={3}>
                1. Purpose of the App
              </Heading>
              <Text>
                The vKutumb Medicine Reminder App is designed primarily to assist elderly individuals and their
                caregivers with medication management. However, the app is accessible to users of all ages who may
                benefit from its features.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                2. User Responsibilities
              </Heading>
              <List spacing={3}>
                <ListItem>
                  <strong>Accuracy of Information:</strong> You are responsible for ensuring the accuracy of the
                  medication details, schedules, and any other data entered into the app.
                </ListItem>
                <ListItem>
                  <strong>Compliance with Medical Advice:</strong> The app is a tool for medication management and does
                  not provide medical advice or replace consultation with healthcare professionals. Always consult a
                  doctor for medical concerns.
                </ListItem>
                <ListItem>
                  <strong>Appropriate Use:</strong> The app is intended for personal use only. Any misuse, such as
                  automation for commercial purposes or any unlawful use, is prohibited.
                </ListItem>
              </List>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                3. Eligibility to Use
              </Heading>
              <Text>
                Users must be 18 years or older to create an account. Minors under the age of 18 must use the app under
                the supervision of a parent or legal guardian, who consents to these T&Cs.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                4. Data Privacy and Security
              </Heading>
              <Text>
                We value your privacy and are committed to protecting the personal information you share with us. By
                using the app, you consent to the data practices outlined in this Privacy Policy.
              </Text>
              <Text fontWeight="bold" mt={4}>
                Data We Collect:
              </Text>
              <List spacing={2}>
                <ListItem>Personal Information: Name, age, contact details, etc.</ListItem>
                <ListItem>
                  Health Information: Medication details, allergies, reminders, appointment schedules, etc.
                </ListItem>
                <ListItem>
                  Device Information: Device type, usage patterns, operating system, and other diagnostic data for app
                  optimization.
                </ListItem>
              </List>

              <Text fontWeight="bold" mt={4}>
                How We Use Your Data:
              </Text>
              <List spacing={2}>
                <ListItem>To set up and manage medication reminders.</ListItem>
                <ListItem>To notify caregivers or family members about missed doses or scheduled medications.</ListItem>
                <ListItem>To improve the app’s functionality and features.</ListItem>
                <ListItem>To provide personalized insights and recommendations based on the data entered.</ListItem>
              </List>

              <Text fontWeight="bold" mt={4}>
                Data Security:
              </Text>
              <Text>
                We implement industry-standard encryption and security protocols to protect your data. Only authorized
                personnel can access your information, and we regularly conduct audits to ensure system security.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                5. Third-Party Services
              </Heading>
              <Text>
                The app may integrate with trusted third-party services (e.g., health platforms or calendar apps) to
                provide additional features. You can opt-out of these integrations at any time.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                6. User Rights Over Their Data
              </Heading>
              <List spacing={2}>
                <ListItem>Access: You can request a copy of your data stored in our system.</ListItem>
                <ListItem>
                  Correction/Deletion: You can update or delete your personal information at any time by accessing your
                  account settings or contacting us.
                </ListItem>
                <ListItem>
                  Withdrawal of Consent: You can withdraw your consent for data collection, though some features may
                  become unavailable.
                </ListItem>
              </List>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                7. Limitation of Liability
              </Heading>
              <Text>
                vKutumb is not responsible for any adverse effects, missed doses, or errors resulting from incorrect or
                incomplete information entered into the app. The app is provided "as-is" and without warranties of any
                kind. While we strive for accuracy and reliability, we do not guarantee uninterrupted or error-free
                operation of the app.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                8. Intellectual Property
              </Heading>
              <Text>
                All app content, including design, logos, and features, is owned by vKutumb. Users are prohibited from
                reproducing, distributing, or modifying any part of the app without prior written consent.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                9. Termination of Use
              </Heading>
              <Text>
                vKutumb reserves the right to suspend or terminate your account if you violate these Terms and
                Conditions or misuse the app.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                10. Retention of Data
              </Heading>
              <Text>
                We retain your personal information for as long as you use the app or until you request its deletion. If
                you delete your account, we will erase all personal data except where retention is required by law or
                for resolving disputes.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                11. Updates and Modifications
              </Heading>
              <Text>
                We may update this combined Terms and Conditions and Privacy Policy from time to time. If significant
                changes occur, you will be notified via email or app notifications.
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                12. Governing Law and Disputes
              </Heading>
              <Text>
                These T&Cs and Privacy Policy are governed by the laws of India. Any disputes related to the use of the
                app will be subject to the jurisdiction of the courts in [Insert City/State].
              </Text>
            </Box>

            <Box>
              <Heading as="h3" size="md" mb={3}>
                13. Contact Us
              </Heading>
              <Text>For any questions, concerns, or support, please reach out to us at:</Text>
              <Text>
                Email: <Link href="mailto:support@vkutumb.com">support@vkutumb.com</Link>
              </Text>
              <Text>Phone: +91-8010630611</Text>
            </Box>

            <Divider my={6} />

            <Text textAlign="center">
              By using the vKutumb Medicine Reminder App, you confirm that you have read and understood this combined
              Terms and Conditions and Privacy Policy, and agree to comply with the outlined provisions.
            </Text>
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default TermsAndConditions;
