import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Avatar, Badge, Button, Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import React from "react";

function RelativesTableRow(props) {
  const { relative, handleEdit, handleDelete } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="20px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={relative.avatar} name={relative.name} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth="100%">
              {relative.name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {relative.gender}, {relative.age} years
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {relative.address}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {relative.phoneNumber}
          </Text>
        </Flex>
      </Td>
      {/* <Td>
        <Badge
          bg={status === "Online" ? "green.400" : bgStatus}
          color={status === "Online" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      </Td> */}
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {relative.relation}
        </Text>
      </Td>
      <Td>
        <Button leftIcon={<EditIcon/>} colorScheme="blue" variant="ghost" onClick={() => handleEdit(relative)}>
          {/* <Text fontSize="md" color="blue.400" fontWeight="bold" cursor="pointer"> */}
            Edit
          {/* </Text> */}
        </Button>
        <Button ml="10px" leftIcon={<DeleteIcon/>} colorScheme="red" variant="ghost" onClick={() => handleDelete(relative._id)}>
          {/* <Text fontSize="md" color="blue.400" fontWeight="bold" cursor="pointer"> */}
            Delete
          {/* </Text> */}
        </Button>
      </Td>
    </Tr>
  );
}

export default RelativesTableRow;
