// Chakra Imports
import { Box, Flex, Spacer, Text, useColorModeValue } from "@chakra-ui/react";
import AdminNavbarLinks from "./AdminNavbarLinks";
import { userRoutes, adminRoutes } from "./routes";
import { useLocation } from "react-router-dom";

export default function AdminNavbar(props) {
  const { variant, children, onOpen, ...rest } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("teal.700", "gray.200");

  let location = useLocation();

  const activeRoute = userRoutes.find((route) => route.path === location.pathname);

  const routeName = activeRoute ? activeRoute.name : "Dashboard"

  return (
    <Flex
      position="fixed"
      boxShadow={"lg"}
      bg="white"
      alignItems="center"
      borderRadius="16px"
      display="flex"
      minH="55px"
      justifyContent="center"
      ml="50px"
      right={{ sm: "5px", md: "30px" }}
      px={{
        sm: "15px",
        md: "30px",
      }}
      top="18px"
      w={{ sm: "calc(100vw - 30px)", xl: "calc(100vw - 75px - 275px)" }}
    >
      <Flex w="100%" alignItems={{ xl: "center" }} justify={"right"}>
        <Spacer />
        <Box>
          <Text
            color={mainText}
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize={"2xl"}
            ml="10px"
          >
            {routeName}
          </Text>
        </Box>
        <Spacer />
        <Box>
          <AdminNavbarLinks onOpen={props.onOpen} logoText={props.logoText} />
        </Box>
      </Flex>
    </Flex>
  );
}
