import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";

function TermsConditionsModal({ isOpen, onClose, handleAccept, handleReject }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW={{sm :"95%", lg: "60%"}} >
        <ModalHeader>Terms and Conditions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box maxH="600px"  overflowY="auto">
            <Text>
              <Text fontWeight="bold" mb={4}>Effective Date: 1st August 2024</Text>
              <Text mb={4}>
                Welcome to “vKutumb Technologies” ("Company", "we", "our",
                "us"). These Terms and Conditions ("Terms") govern your use of
                our services, which facilitate doctor visits for the elderly
                ("Services"). By using our Services, you agree to comply with
                and be bound by these Terms.
              </Text>

              <Text fontWeight="bold">1. Introduction</Text>
              <Text mb={4}>
                Welcome to “vKutumb Technologies” ("Company", "we", "our",
                "us"). These Terms and Conditions ("Terms") govern your use of
                our services, which facilitate doctor visits for the elderly
                ("Services"). By using our Services, you agree to comply with
                and be bound by these Terms.
              </Text>

              <Text fontWeight="bold">2. Eligibility</Text>
              <Text mb={4}>
                Our Services are intended for individuals who are 18 years of
                age or older. By using our Services, you represent and warrant
                that you meet this eligibility requirement.
              </Text>

              <Text fontWeight="bold">3. Services</Text>
              <Text fontWeight="bold" ml={4}>
                3.1 Scope of Services
              </Text>
              <Text mb={4}>
                Our Services include scheduling, coordinating, and facilitating
                doctor visits for elderly individuals. We do not provide medical
                advice, diagnosis, or treatment.
              </Text>
              <Text fontWeight="bold" ml={4}>
                3.2 Service Limitations
              </Text>
              <Text mb={4}>
                Our Services are subject to availability and may be modified,
                suspended, or discontinued at any time without prior notice.
              </Text>

              <Text fontWeight="bold">4. User Responsibilities</Text>
              <Text fontWeight="bold" ml={4}>
                4.1 Accurate Information
              </Text>
              <Text mb={4}>
                You agree to provide accurate and complete information when
                using our Services and to update such information as necessary.
              </Text>
              <Text fontWeight="bold" ml={4}>
                4.2 Compliance with Laws
              </Text>
              <Text mb={4}>
                You agree to use our Services in compliance with all applicable
                laws, regulations, and these Terms.
              </Text>

              <Text fontWeight="bold">5. Privacy</Text>
              <Text mb={4}>
                Your use of our Services is subject to our Privacy Policy, which
                explains how we collect, use, and protect your personal
                information. By using our Services, you consent to our Privacy
                Policy. We comply with the Information Technology (Reasonable
                Security Practices and Procedures and Sensitive Personal Data or
                Information) Rules, 2011.
              </Text>

              <Text fontWeight="bold">6. Fees and Payment</Text>
              <Text fontWeight="bold" ml={4}>
                6.1 Fees
              </Text>
              <Text mb={4}>
                Fees for our Services are as specified on our website or as
                otherwise agreed upon. We reserve the right to change our fees
                at any time.
              </Text>
              <Text fontWeight="bold" ml={4}>
                6.2 Payment
              </Text>
              <Text mb={4}>
                You agree to pay all fees associated with the Services you
                select in accordance with the payment terms provided. Payments
                can be made through various methods, including credit/debit
                cards, net banking, or other payment gateways, subject to terms
                and conditions of the respective payment providers.
              </Text>

              <Text fontWeight="bold">7. Cancellation and Refunds</Text>
              <Text fontWeight="bold" ml={4}>
                7.1 Cancellation
              </Text>
              <Text mb={4}>
                You may cancel our Services by providing 3 days' notice.
                Cancellation policies may vary and will be detailed at the time
                of booking.
              </Text>
              <Text fontWeight="bold" ml={4}>
                7.2 Refunds
              </Text>
              <Text mb={4}>
                Refunds will be issued in accordance with our refund policy,
                which is available on our website.
              </Text>

              <Text fontWeight="bold">8. Limitation of Liability</Text>
              <Text mb={4}>
                To the maximum extent permitted by law, we shall not be liable
                for any indirect, incidental, special, or consequential damages
                arising out of or in connection with your use of our Services.
              </Text>

              <Text fontWeight="bold">9. Indemnification</Text>
              <Text mb={4}>
                You agree to indemnify, defend, and hold harmless the Company,
                its affiliates, officers, directors, employees, and agents from
                any claims, liabilities, damages, losses, or expenses arising
                out of or in connection with your use of our Services or
                violation of these Terms.
              </Text>

              <Text fontWeight="bold">10. Governing Law</Text>
              <Text mb={4}>
                These Terms shall be governed by and construed in accordance
                with the laws of India, without regard to its conflict of laws
                principles.
              </Text>

              <Text fontWeight="bold">11. Dispute Resolution</Text>
              <Text mb={4}>
                Any disputes arising out of or in connection with these Terms
                shall be resolved through negotiation, mediation, or binding
                arbitration in accordance with the Arbitration and Conciliation
                Act, 1996, and any amendments thereto. The place of arbitration
                shall be [Your Jurisdiction], and the language of arbitration
                shall be English.
              </Text>

              <Text fontWeight="bold">12. Amendments</Text>
              <Text mb={4}>
                We reserve the right to modify these Terms at any time. Any
                changes will be effective immediately upon posting on our
                website. Your continued use of our Services constitutes your
                acceptance of the amended Terms.
              </Text>

              <Text fontWeight="bold">13. Contact Us</Text>
              <Text mb={4}>
                If you have any questions or concerns about these Terms, please
                contact us at: vKutumb Technologies, support@vKutumb.com.
              </Text>
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleAccept}>
            Accept
          </Button>
          <Button variant="ghost" onClick={handleReject}>
            Reject
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TermsConditionsModal;
