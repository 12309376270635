import api from "../api";

export async function relativeDataLoader() {
  const res = await api.get("/api/relatives");
  // console.log("Response data ", res.data);
  const relativeData = await res.data;

  const resBookings = await api.get("/api/bookings");
  // console.log("Booking Response data ", resBookings.data);
  const bookings = await resBookings.data;

  return { relativeData, bookings };
  // return relativeDummyData;
}
