// src/pages/AboutUs.js
import React from "react";
import {
  Box,
  Flex,
  Text,
  Stack,
  Image,
  Divider,
  Button,
  useBreakpointValue,
  GridItem,
  Grid,
  VStack,
} from "@chakra-ui/react";
import Header from "./Header";
import collage1 from "./img/Collage1.jpeg";
import collage2 from "./img/Collage2.jpeg";
import collage3 from "./img/Collage3.jpeg";
import helpingOldMan from "./img/HelpingOldMan.jpeg";
import prem from "./img/Prem.jpeg";
import devesh from "./img/Devesh.jpg";
import companyVision from "./img/CompanyVision.jpeg";

const founders = [
  {
    name: "Prem Kumar",
    role: "Co-Founder & CEO",
    image: prem,
    bio: "Prem is a dynamic leader with a passion for turning bold ideas into reality. With over 20 years of experience in the tech industry, he brings a wealth of knowledge and a visionary approach to guiding our startup toward success.",
  },
  {
    name: "Devesh Kumar Singh",
    role: "Co-Founder & CTO",
    image: devesh,
    bio: "Devesh is the innovative mastermind behind our technology, driving the creation of a cutting-edge  tech stack that sets vKutumb for success. His relentless pursuit of innovation and technical excellence is key to our early-stage growth and future potential.",
  },
];

const teamMembers = [
  {
    name: "Alice Johnson",
    role: "Lead Designer",
    image: "https://example.com/alice-johnson.jpg",
  },
  {
    name: "Bob Brown",
    role: "Marketing Specialist",
    image: "https://example.com/bob-brown.jpg",
  },
];

const AboutUs = () => {
  const layout = useBreakpointValue({ base: "column", md: "row" });

  return (
    <Box>
      <Header />

      {/* Hero Section */}
      <Box position="relative" height="500px" overflow="hidden">
        {/* Collage of Images */}
        <Grid templateColumns="repeat(4, 1fr)" templateRows="repeat(2, 1fr)" height="100%" gap={2}>
          <GridItem colSpan={2} rowSpan={2}>
            <Image
              src={collage1} // Replace with actual image URLs
              alt="Hero Image 1"
              objectFit="cover"
              width="100%"
              height="100%"
            />
          </GridItem>
          <GridItem>
            <Image src={collage2} alt="Hero Image 2" objectFit="cover" width="100%" height="100%" />
          </GridItem>
          <GridItem>
            <Image src={collage3} alt="Hero Image 3" objectFit="cover" width="100%" height="100%" />
          </GridItem>
          <GridItem colSpan={2}>
            <Image src={helpingOldMan} alt="Hero Image 4" objectFit="cover" width="100%" height="100%" />
          </GridItem>
        </Grid>

        {/* Overlay Text */}
        <Flex
          direction="column"
          align="center"
          justify="center"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(0, 0, 0, 0.6)"
          color="white"
          textAlign="center"
          padding="4"
        >
          <Text fontSize="5xl" fontWeight="bold" mb="4">
            About Us
          </Text>
          <Text fontSize="xl" mb="6">
            Discover our story, mission, and the team that drives our success.
          </Text>
          <Button colorScheme="blue" variant="solid" size="lg">
            Learn More
          </Button>
        </Flex>
      </Box>

      {/* About Company Section */}
      <Box paddingY="16" bg="gray.100">
        <Flex direction="column" align="center" maxWidth="1200px" margin="auto" paddingX="8">
          <Text fontSize="4xl" fontWeight="bold" mb="6" textAlign="center">
            Our Company
          </Text>
          <Text fontSize="lg" textAlign="left" mb="8">
            At vKutumb, we understand the unique challenges faced by elderly citizens whose children live far away. Our
            mission is to provide compassionate and comprehensive healthcare services designed to meet the needs of your
            loved ones, ensuring their well-being and offering peace of mind to you and your family.
          </Text>
          <Text fontSize="lg" textAlign="left" mb="8" fontWeight={"bold"}>
            Our Services:
          </Text>
          <VStack gap="2" align="left">
            <Text fontSize="lg" textAlign="left" mb="8">
              Doctor Visits: Regular check-ups and medical consultations to ensure ongoing health and comfort.
            </Text>
            <Text fontSize="lg" textAlign="left" mb="8">
              Hospital Visits: Thoughtful assistance with hospital visits, including transportation and support during
              medical procedures.
            </Text>
            <Text fontSize="lg" textAlign="left" mb="8">
              What truly sets us apart is our heartfelt commitment to communication and transparency. After each
              service, we prepare a detailed and easy-to-understand report that explains everything that happened during
              the visit. This report is shared with you, so you can stay informed about your parent's health and feel
              reassured knowing they are in good hands. At vKutumb, we bridge the gap between distance and care,
              offering devoted support to elderly citizens while keeping their families closely connected to their
              health journey. Reach out to us today to discover how we can support your loved ones with exceptional
              healthcare services and comforting updates.
            </Text>
          </VStack>
          <Stack spacing="20" direction={layout} align="center" mt="20px">
            <Image src={companyVision} alt="Company Vision" borderRadius="md" boxSize="400px" objectFit="cover" />
            <Box>
              <Text fontSize="lg">
                Our mission is to lead through innovation and excellence, delivering solutions that make a difference.
                We envision a future where our technology transforms industries and improves lives.
              </Text>
            </Box>
          </Stack>
        </Flex>
      </Box>

      {/* Founders Section */}
      <Box paddingY="16">
        <Flex direction="column" align="center" maxWidth="1500px" margin="auto" paddingX="8">
          <Text fontSize="4xl" fontWeight="bold" mb="8" textAlign="center">
            Meet Our Founders
          </Text>
          <Flex direction={{ base: "column", md: "row" }} wrap="wrap" justify="space-around" spacing="8">
            {founders.map((founder, index) => (
              <Box
                display="flex"
                key={index}
                width={{ base: "100%", md: "45%" }}
                mb="8"
                textAlign="center"
                borderWidth="1px"
                borderRadius="md"
                overflow="hidden"
                boxShadow="lg"
                transition="transform 0.3s"
                _hover={{ transform: "scale(1.05)" }}
                alignItems="center"
              >
                <Image
                  src={founder.image}
                  alt={founder.name}
                  borderTopRadius="md"
                  boxSize="200px"
                  objectFit="contain"
                  justify="center"
                />
                <Box p="6">
                  <Text fontSize="xl" fontWeight="bold">
                    {founder.name}
                  </Text>
                  <Text fontSize="md" fontWeight="semibold" mb="2">
                    {founder.role}
                  </Text>
                  <Text>{founder.bio}</Text>
                </Box>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Box>

      {/* Team Section */}
      {/* <Box paddingY="16" bg="gray.100">
        <Flex direction="column" align="center" maxWidth="1200px" margin="auto" paddingX="8">
          <Text fontSize="4xl" fontWeight="bold" mb="8" textAlign="center">
            Our Team
          </Text>
          <Flex direction={{ base: "column", md: "row" }} wrap="wrap" justify="center" spacing="8">
            {teamMembers.map((member, index) => (
              <Box
                key={index}
                width={{ base: "100%", md: "30%" }}
                mb="8"
                textAlign="center"
                borderWidth="1px"
                borderRadius="md"
                overflow="hidden"
                boxShadow="lg"
                transition="transform 0.3s"
                _hover={{ transform: "scale(1.05)" }}
              >
                <Image src={member.image} alt={member.name} borderTopRadius="md" boxSize="150px" objectFit="cover" />
                <Box p="6">
                  <Text fontSize="lg" fontWeight="bold">
                    {member.name}
                  </Text>
                  <Text fontSize="md">{member.role}</Text>
                </Box>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Box> */}
    </Box>
  );
};

export default AboutUs;
