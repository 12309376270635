// src/components/Footer.js
import React from 'react';
import { Box, Flex, Link, VStack, HStack, Text } from '@chakra-ui/react';

const Footer = () => (
  <Box bg="teal.700" color="white" p={8}>
    <Flex justifyContent="space-between">
      <VStack align="flex-start">
        <Text fontSize="lg" fontWeight="bold">Company</Text>
        <Link href="/aboutus">About Us</Link>
        {/* <Link href="/careers">Careers</Link> */}
        <Link href="/blogs">Blog</Link>
      </VStack>
      <VStack align="flex-start">
        <Text fontSize="lg" fontWeight="bold">Products</Text>
        <Link href="/login">Book</Link>
        <Link href="/pricing">Pricing</Link>
      </VStack>
      <VStack align="flex-start">
        <Text fontSize="lg" fontWeight="bold">Support</Text>
        <Link href="/contactus">Contact Us</Link>
        {/* <Link href="#">Safety</Link> */}
        <Link href="#">FAQs</Link>
      </VStack>
    </Flex>
    <Text mt={8} textAlign="center">&copy; 2024 @vKutumb. All rights reserved.</Text>
  </Box>
);

export default Footer;
