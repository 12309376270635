import { relativeDataLoader } from "../../hooks/RelativeDataLoader";
import api from "../../api";

function BookingDataHandler(revalidator, setBookings, setRelatives) {
  let currentBookingId = null;
  let currentRelative = null;
  // let that =this;

  this.loadRelativeData = async function () {
    console.log("Loading Relative data...");
    let { relativeData, bookings } = await relativeDataLoader();
    if (relativeData) {
      this.relatives = relativeData;
      setRelatives(relativeData);
    }

    if (bookings) {
      //convert bookings to a object.
      var bookingObj = {};
      for (var i = 0; i < bookings.length; i++) {
        if (bookings[i].relative) {
          // Relative should be there - if its deleted - ideally we should have removed this booking, 
          // but this is a band aid till that is fixed in BE. 
          bookingObj[bookings[i].bookingId] = bookings[i];
        }
      }
      this.bookings = bookingObj;
      setBookings(bookingObj);
      console.log("Booking set.. ");
    }
  };

  this.addRelativeToLocal = function (relativeData) {
    this.relatives.push(relativeData);
    setRelatives(this.relatives);
    if (revalidator) revalidator.revalidate();
  };

  this.addBookingToState = function (booking) {
    console.log("addingBooking :: ", booking);
    this.bookings[booking.bookingId] = booking;
    console.log("Booking data :: ", this.bookings);
    setBookings(this.bookings);
  };

  this.removeBookingFromState = function (booking) {
    console.log("removingBooking :: ", booking.bookingId);
    delete this.bookings[booking.bookingId];
    console.log("After delete:: ", this.bookings);
    setBookings(this.bookings);
  };

  this.removeBooking = async function (booking) {
    var res = await api.put("/api/bookings/" + booking.bookingId, { status: "Cancelled" });
    this.removeBookingFromState(booking);
    if (revalidator) revalidator.revalidate();
  };

  this.addUpdateBooking = async function (bookingDetails) {
    if (bookingDetails.bookingId) {
      // Update case
      var res = await api.put("/api/bookings/" + bookingDetails.bookingId, bookingDetails);
      console.log("Updated booking", res);
      this.addBookingToState(res.data);
    } else {
      var res = await api.post("/api/bookings", bookingDetails);
      bookingDetails["bookingId"] = res.data.bookingId;
      currentBookingId = res.data.bookingId;
      this.addBookingToState(res.data);
    }
    if (revalidator) revalidator.revalidate();
    return bookingDetails;
  };

  this.setSelectedBooking = function (bookingId) {
    currentBookingId = bookingId;
  };

  this.getCurrentBooking = function () {
    return this.bookings[currentBookingId];
  };

  this.setSelectedRelative = function (relative) {
    currentRelative = relative;
  };

  this.getCurrentRelative = function () {
    return currentRelative;
  };

  this.clearCurrentState = function () {
    currentBookingId = null;
    currentRelative = null;
  };

  this.getQRCode = async function (setQRElement, toast, relative) {
    // Get QR code from BE.
    try {
      var qrEl = await api.post("/api/util/generateQR", {
        relative: relative ? relative : this.getCurrentBooking().relative,
      });
      // console.log(qrEl);
      setQRElement(qrEl.data);
    } catch (error) {
      toast({
        title: "Error Fetching QR Code.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return {
    ...this,
  };
}

export default BookingDataHandler;
