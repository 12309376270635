import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { Field } from "formik";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import React, { useState } from "react";

const PasswordConfirm = ({ errors, touched, values, setFieldValue }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <>
      <FormControl isInvalid={errors.password && touched.password} mb="6px">
        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          Password
        </FormLabel>
        <InputGroup>
          <Field
            as={Input}
            id="password"
            name="password"
            fontSize="sm"
            ms="4px"
            borderRadius="15px"
            type={showPassword ? "text" : "password"}
            placeholder="Your password"
            size="lg"
          />
          <InputRightElement>
            <IconButton
              variant="ghost"
              onClick={toggleShowPassword}
              aria-label={showPassword ? "Hide password" : "Show password"}
              icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{errors.password}</FormErrorMessage>
      </FormControl>
      <Text fontSize="xs" mt="1" color="gray.400" ml="10px" mb="24px">
        Your password should have:
        <Text fontSize="xs" mt="1" color="gray.400" ml="20px">
          <ul>
            <li>At least 8 characters</li>
            <li>At least one uppercase letter</li>
            <li>At least one special character</li>
            <li>At least one numeric digit</li>
          </ul>
        </Text>
      </Text>
      <FormControl
        isInvalid={errors.confirmPassword && touched.confirmPassword}
        mb="36px"
      >
        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          Confirm Password
        </FormLabel>
        <InputGroup>
          <Field
            as={Input}
            id="confirmPassword"
            name="confirmPassword"
            fontSize="sm"
            ms="4px"
            borderRadius="15px"
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm your password"
            size="lg"
          />
          <InputRightElement>
            <IconButton
              variant="ghost"
              onClick={toggleShowConfirmPassword}
              aria-label={
                showConfirmPassword ? "Hide password" : "Show password"
              }
              icon={showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
      </FormControl>
    </>
  );
};

export default PasswordConfirm;
