// Chakra imports
import { Box, Card, CardBody, CardHeader, Circle, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import TimelineRow from "../../components/TimelineRow";
import React from "react";
import { FiCheckCircle } from "react-icons/fi";

const VisitProcessOverview = ({ title, amount, data }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card maxH="100%" variant="unstyled">
      <CardHeader p="22px 0px 35px 14px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
            {title}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody ps="20px" pe="0px" mb="31px" position="relative">
        <Flex direction="column">
          {data.map((row, index, arr) => {
            return (
              <MilestoneItem icon={row.logo} skipTrail={index === arr.length - 1} iconColor={row.color}>
                {row.title}
              </MilestoneItem>
            );
          })}
        </Flex>
      </CardBody>
    </Card>
  );
};

const MilestoneItem = ({ icon = FiCheckCircle, boxProps = {}, skipTrail = false, children, iconColor, ...props }) => {
  const color = useColorModeValue("gray.600", "gray.300");
  return (
    <Flex minH={20} {...props}>
      <Flex flexDir="column" alignItems="center" mr={4} pos="relative">
        <Circle size={12} bg={iconColor} opacity={useColorModeValue(0.07, 0.15)} />
        <Box as={icon} size="1.25rem" color={iconColor} pos="absolute" left="0.875rem" top="0.875rem" />
        {!skipTrail && <Box w="2px" flex={1} bg={color} my={1} />}
      </Flex>
      <Box pt={{ base: 1, sm: 3 }} {...boxProps}>
        {children}
      </Box>
    </Flex>
  );
};

export default VisitProcessOverview;
