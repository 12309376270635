import { Box, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../pages/Home/Header";
import Footer from "../../pages/Home/Footer";

function AuthLayout({ children }) {
  return (
    <Flex direction="column" minHeight="100vh" justifyContent="space-between" maxW="100%">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <Box as="main" width="100%">
        <Outlet />
      </Box>

      {/* Footer */}
      <Box as="footer" width="100%">
        <Footer />
      </Box>
    </Flex>
  );
}

export default AuthLayout;
