import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Container,
  Heading,
  Switch,
  Tag,
  Icon,
  Divider,
  Grid,
  GridItem,
  Center,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import Header from "./Header";

const PricingPlan = ({ title, description, price, billedPrice, features, included, isMedical }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Grid
      templateRows="auto auto 1fr auto"
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      bg={bgColor}
      width="450px"
      height="100%"
      gap={4}
      p={6}
    >
      <GridItem>
        <Center>
          <Text fontSize="3xl" fontWeight="bold">
            {title}
          </Text>
        </Center>
        <Text fontSize="sm" color="gray.500" height="40px" mb="20px" mt="10px">
          {description}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize="4xl" fontWeight="bold">
          ${price}
        </Text>
        <Text fontSize="sm" color="gray.500">
          per visit, per user.
        </Text>
      </GridItem>
      <GridItem>
        <Button
          colorScheme={isMedical ? "teal" : "orange"}
          variant={"solid"}
          width="100%"
          mb={4}
          onClick={() => (window.location.href = "/user/dashboard")}
        >
          Book now
        </Button>
        <Divider mb={4} />
        <Text fontWeight="semibold" mb={2}>
          What's included:
        </Text>
        <VStack align="stretch" spacing={2}>
          {features.map((feature, index) => (
            <HStack key={index}>
              {included.includes(feature) ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />}
              <Text fontSize="sm">{feature}</Text>
            </HStack>
          ))}
        </VStack>
      </GridItem>
    </Grid>
  );
};

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const plans = [
    {
      title: "Doctor/Hostpital Visit",
      description:
        "Professional companions accompany your parents to their medical appointments, ensuring they receive the care they deserve.",
      price: "20 / ₹1699",
      billedPrice: "$0",
      features: [
        "Medically trained professionals greet you at your doorstep",
        "Easy mobility with wheelchair assistance.",
        "Detailed guidance on appointments and directions",
        "Smooth return after the visit with transport arrangements",
        "Visit summary with key follow-up steps",
        "vKutumb Mitra spend 60+ minutes to understand and represent elder needs in front of doctors.",
      ],
      included: [
        "Medically trained professionals greet you at your doorstep",
        "Easy mobility with wheelchair assistance.",
        "Detailed guidance on appointments and directions",
        "Smooth return after the visit with transport arrangements",
        "Visit summary with key follow-up steps",
        "vKutumb Mitra spend 60+ minutes to understand and represent elder needs in front of doctors.",
      ],
      isMedical: true,
    },
    // {
    //   title: "Email",
    //   description: "For those looking for just email data.",
    //   price: isAnnual ? "83" : "99",
    //   billedPrice: "$990",
    //   features: ["Unlimited emails", "$0.35 per phone number", "10k/mo export limit", "CRM Integration"],
    //   included: ["Unlimited emails", "$0.35 per phone number", "10k/mo export limit", "CRM Integration"],
    //   isPopular: true,
    // },
    {
      title: "Travel Assistance",
      description: "Ensuring stress-free train journeys with support for boarding, disembarking, and stopovers.",
      price: isAnnual ? "4 / ₹399" : "4",
      billedPrice: "$1990",
      features: [
        "Meet and Greet at the station for a warm start to the journey",
        "Wheelchair assistance for hassle-free mobility",
        "Luggage handling with coordination of local porters",
        "Complete guidance on travel information like train schedules and platforms",
        "Emergency assistance for medical or other urgent needs",
      ],
      included: [
        "Meet and Greet at the station for a warm start to the journey",
        "Wheelchair assistance for hassle-free mobility",
        "Luggage handling with coordination of local porters",
        "Complete guidance on travel information like train schedules and platforms",
        "Emergency assistance for medical or other urgent needs",
      ],
    },
  ];

  return (
    <>
      <Header />

      <Container maxW="container.xl" py={10}>
        <VStack spacing={8} align="center">
          {/* <Tag size="md" variant="subtle" colorScheme="teal">
            <HStack>
              <Icon as={InfoOutlineIcon} />
              <Text>Transparent pricing</Text>
            </HStack>
          </Tag> */}
          <Heading as="h1" size="2xl" textAlign="center">
            Affordable, Five-Star Care for Your Loved Ones
          </Heading>
          <Text fontSize="lg" textAlign="center" color="gray.600">
            Your parents' well-being is invaluable, but ensuring their care doesn't have to break the bank. With
            vKutumb, you provide the best for your loved ones at an affordable price.
          </Text>
          {/* <HStack>
            <Text fontWeight={isAnnual ? "bold" : "normal"}>Bill annually</Text>
            <Switch size="lg" isChecked={!isAnnual} onChange={() => setIsAnnual(!isAnnual)} />
            <Text fontWeight={!isAnnual ? "bold" : "normal"}>Bill monthly</Text>
          </HStack> */}
          <Flex justify="center" align="start" gap={10} wrap="wrap">
            {plans.map((plan, index) => (
              <PricingPlan key={index} {...plan} />
            ))}
          </Flex>
          <Text fontSize="md" textAlign="center" color="gray.600" fontStyle={"italic"}>
            "vKutumb has been a blessing for our family. Knowing my parents are in such good hands has given me peace of
            mind." – *A Grateful Client*
          </Text>
          <Text fontSize="lg" textAlign="center" color="gray.600" mt="50px">
            Why choose vKutumb? For less than the cost of a night out, your parents receive world-class, compassionate
            care delivered by trained professionals.
          </Text>
          <Text fontSize="lg" textAlign="center" color="gray.600" mb="100px">
            Whether it's for doctor visits, hospital stays, or travel assistance, our services are designed to give your
            loved ones the attention they need while providing you with peace of mind. Book today and make a small
            investment for their happiness and your mental peace.
          </Text>
        </VStack>
      </Container>
    </>
  );
};

export default Pricing;

/**
 * 
 * Warm welcome at your doorstep by vKutumb's Mitra.
Easy MobilityEasy mobility with wheelchair assistance.
Helpful GuidanceDetailed guidance on appointments and directions.
Smooth ReturnSmooth return after the visit with transport arrangements.
Visit SummaryVisit summary with key follow-up steps.
Medical StudentsMedical students spend 60+ minutes to understand and represent elder needs in front of doctors.

Meet and Greet at the station for a warm start to the journey.
Wheelchair AssistanceWheelchair assistance for hassle-free mobility.
Luggage HandlingLuggage handling with coordination of local porters.
Information and GuidanceComplete guidance on travel information like train schedules and platforms.
Emergency AssistanceEmergency assistance for medical or other urgent needs.
 */
