// src/components/MedicalForm.js
import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Stack,
  Radio,
  RadioGroup,
  FormErrorMessage,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const MedicalForm = () => {
  const toast = useToast();

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    age: Yup.number().required('Age is required').min(0, 'Age must be a positive number'),
    gender: Yup.string().required('Gender is required'),
    idVerification: Yup.string().required('ID for verification is required'),
    previousIssues: Yup.string(),
    ongoingMedication: Yup.string(),
    allergies: Yup.string(),
    transportationLimits: Yup.string(),
  });

  const handleSubmit = (values) => {
    // console.log(values);
    toast({
      title: 'Form Submitted',
      description: 'Your information has been successfully submitted.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Formik
      initialValues={{
        name: '',
        address: '',
        age: '',
        gender: '',
        idVerification: '',
        previousIssues: '',
        ongoingMedication: '',
        allergies: '',
        transportationLimits: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form >
          <Box
            maxW="md"
            mx="auto"
            p="8"
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="lg"
            bg="white"
            mt="120px"
          >
            <Stack spacing="6">
              {/* Name */}
              <FormControl isInvalid={errors.name && touched.name} isRequired>
                <FormLabel htmlFor="name">Name</FormLabel>
                <Field as={Input} id="name" name="name" placeholder="Enter your name" />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>

              {/* Address */}
              <FormControl isInvalid={errors.address && touched.address} isRequired>
                <FormLabel htmlFor="address">Address</FormLabel>
                <Field as={Textarea} id="address" name="address" placeholder="Enter your address" />
                <FormErrorMessage>{errors.address}</FormErrorMessage>
              </FormControl>

              {/* Age */}
              <FormControl isInvalid={errors.age && touched.age} isRequired>
                <FormLabel htmlFor="age">Age</FormLabel>
                <Field as={Input} id="age" name="age" type="number" placeholder="Enter your age" />
                <FormErrorMessage>{errors.age}</FormErrorMessage>
              </FormControl>

              {/* Gender */}
              <FormControl isInvalid={errors.gender && touched.gender} isRequired>
                <FormLabel htmlFor="gender">Gender</FormLabel>
                <RadioGroup id="gender">
                  <Stack direction="row">
                    <Field as={Radio} name="gender" value="Male">
                      Male
                    </Field>
                    <Field as={Radio} name="gender" value="Female">
                      Female
                    </Field>
                  </Stack>
                </RadioGroup>
                <FormErrorMessage>{errors.gender}</FormErrorMessage>
              </FormControl>

              {/* ID for Verification */}
              <FormControl isInvalid={errors.idVerification && touched.idVerification} isRequired>
                <FormLabel htmlFor="idVerification">ID for Verification</FormLabel>
                <Field
                  as={Input}
                  id="idVerification"
                  name="idVerification"
                  placeholder="Enter your ID for verification"
                />
                <FormErrorMessage>{errors.idVerification}</FormErrorMessage>
              </FormControl>

              {/* Previous Disease/Issues (Optional) */}
              <FormControl isInvalid={errors.previousIssues && touched.previousIssues}>
                <FormLabel htmlFor="previousIssues">Any Previous Disease/Issues</FormLabel>
                <Field
                  as={Textarea}
                  id="previousIssues"
                  name="previousIssues"
                  placeholder="Enter any previous disease or issues (optional)"
                />
                <FormErrorMessage>{errors.previousIssues}</FormErrorMessage>
              </FormControl>

              {/* Ongoing Medication (Optional) */}
              <FormControl isInvalid={errors.ongoingMedication && touched.ongoingMedication}>
                <FormLabel htmlFor="ongoingMedication">Any Ongoing Medication</FormLabel>
                <Field
                  as={Textarea}
                  id="ongoingMedication"
                  name="ongoingMedication"
                  placeholder="Enter any ongoing medication (optional)"
                />
                <FormErrorMessage>{errors.ongoingMedication}</FormErrorMessage>
              </FormControl>

              {/* Allergies (Optional) */}
              <FormControl isInvalid={errors.allergies && touched.allergies}>
                <FormLabel htmlFor="allergies">Any Allergies</FormLabel>
                <Field
                  as={Textarea}
                  id="allergies"
                  name="allergies"
                  placeholder="Enter any allergies (optional)"
                />
                <FormErrorMessage>{errors.allergies}</FormErrorMessage>
              </FormControl>

              {/* Preferred Transportation Limits (Optional) */}
              <FormControl isInvalid={errors.transportationLimits && touched.transportationLimits}>
                <FormLabel htmlFor="transportationLimits">Preferred Transportation Limits</FormLabel>
                <Field
                  as={Textarea}
                  id="transportationLimits"
                  name="transportationLimits"
                  placeholder="Enter your preferred transportation limits (optional)"
                />
                <FormErrorMessage>{errors.transportationLimits}</FormErrorMessage>
              </FormControl>

              <Button type="submit" colorScheme="blue" size="lg" width="full">
                Submit
              </Button>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MedicalForm;
