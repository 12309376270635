// Chakra imports
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import the phone input CSS
import { Field, ErrorMessage } from "formik";

const PhoneInputField = ({ name, label, country, onlyCountries }) => {
  if (!country) {
    country = "us";
  }

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
            {label}
          </FormLabel>
          <PhoneInput
            onlyCountries={onlyCountries}
            country={country} // Default country, can be changed
            value={field.value}
            onChange={(phone) => form.setFieldValue(name, phone)}
            inputProps={{
              name: name,
              id: name,
            }}
            inputStyle={{
              width: "100%",
              borderRadius: "15px",
              border: "1px solid #E2E8F0",
              paddingLeft: "48px", // Adjusted to avoid overlap with the country code dropdown
              paddingRight: "12px", // Standard padding on the right
              fontSize: "sm",
              ms: "4px",
              height: "50px", // Adjusted height to match Chakra's Input component
            }}
            containerStyle={{
              width: "100%",
            }}
            buttonStyle={{
              borderRadius: "15px 0 0 15px",
              border: "1px solid #E2E8F0",
              height: "50px", // Ensures the dropdown matches the height of the input field
              background: "transparent",
            }}
          />
          <FormErrorMessage>
            <ErrorMessage name={name} />
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default PhoneInputField;
