import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';


const RecaptchaComponent = forwardRef(({ onChange }, ref) => (
  <ReCAPTCHA
    ref={ref}
    sitekey="6LduGhoqAAAAAJZhf71CvBAzV-wZnzxEerPyzCHz"
    onChange={onChange}
  />
));



export default RecaptchaComponent;