// src/components/ReportModal.js
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Heading,
  Divider,
  Stack,
} from "@chakra-ui/react";

const ReportModal = ({ isOpen, onClose, reportData }) => {

  console.log("ReportDAta on ReportModal, :: ",reportData)

  if(!reportData) {
    return;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bgColor="teal.700">Doctor Visit Report</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Heading size="md" mb="4">
              vKutumb - Doctor Visit Report
            </Heading>
            <Text>
              <strong>Date of Visit:</strong> {reportData.visitDate}
            </Text>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              Patient Information:
            </Heading>
            <Text>
              <strong>Name:</strong> {reportData.patientName}
            </Text>
            <Text>
              <strong>Age:</strong> {reportData.patientAge}
            </Text>
            <Text>
              <strong>Gender:</strong> {reportData.patientGender}
            </Text>
            <Text>
              <strong>Contact Information:</strong> {reportData.patientContact}
            </Text>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              1. Issues Reported by Patient
            </Heading>
            <Stack spacing="2">
              {reportData.issues.map((issue, index) => (
                <Text key={index}>{issue}</Text>
              ))}
            </Stack>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              2. Diagnosis by Doctor
            </Heading>
            <Text>
              <strong>Primary Diagnosis:</strong> {reportData.primaryDiagnosis}
            </Text>
            <Text>
              <strong>Secondary Diagnosis:</strong> {reportData.secondaryDiagnosis}
            </Text>
            <Text>
              <strong>Comments:</strong> {reportData.doctorComments}
            </Text>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              3. Tests Conducted
            </Heading>
            <Stack spacing="2">
              {reportData.tests.map((test, index) => (
                <Box key={index}>
                  <Text>
                    <strong>Test Name:</strong> {test.name}
                  </Text>
                  <Text>
                    <strong>Result:</strong> {test.result}
                  </Text>
                  <Text>
                    <strong>Comments:</strong> {test.comments}
                  </Text>
                </Box>
              ))}
            </Stack>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              4. Prescribed Medicines
            </Heading>
            <Stack spacing="4">
              {reportData.medicines.map((medicine, index) => (
                <Box key={index}>
                  <Text>
                    <strong>Medicine Name:</strong> {medicine.name}
                  </Text>
                  <Text>
                    <strong>Dosage:</strong> {medicine.dosage}
                  </Text>
                  <Text>
                    <strong>Frequency:</strong> {medicine.frequency}
                  </Text>
                  <Text>
                    <strong>Duration:</strong> {medicine.duration}
                  </Text>
                  <Text>
                    <strong>Comments:</strong> {medicine.comments}
                  </Text>
                </Box>
              ))}
            </Stack>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              5. Follow-up Instructions
            </Heading>
            <Text>
              <strong>Next Appointment:</strong> {reportData.nextAppointment}
            </Text>
            <Text>
              <strong>Special Instructions:</strong> {reportData.specialInstructions}
            </Text>
            <Text>
              <strong>Contact Information:</strong> {reportData.doctorContact}
            </Text>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              6. Additional Recommendations
            </Heading>
            <Text>
              <strong>Lifestyle Changes:</strong> {reportData.lifestyleChanges}
            </Text>
            <Text>
              <strong>Preventive Measures:</strong> {reportData.preventiveMeasures}
            </Text>
            <Text>
              <strong>Referrals:</strong> {reportData.referrals}
            </Text>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              7. Emergency Contact Information
            </Heading>
            <Text>{reportData.emergencyContact}</Text>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              8. Patient Feedback
            </Heading>
            <Text>{reportData.feedback}</Text>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              9. Contact Us
            </Heading>
            <Text>
              <strong>Your Company Name:</strong> {reportData.companyName}
            </Text>
            <Text>
              <strong>Address:</strong> {reportData.companyAddress}
            </Text>
            <Text>
              <strong>Email:</strong> {reportData.companyEmail}
            </Text>
            <Text>
              <strong>Phone:</strong> {reportData.companyPhone}
            </Text>
            <Divider my="4" />

            <Heading size="sm" mb="2">
              Disclaimer:
            </Heading>
            <Text>{reportData.disclaimer}</Text>
          </Box>
        </ModalBody>

        <ModalFooter bgColor="teal.700">
          <Button colorScheme="teal" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReportModal;
