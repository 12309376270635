import { extendTheme } from "@chakra-ui/react";
import { globalStyles } from "./styles";
import { breakpoints } from "./foundations/breakpoints";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { linkStyles } from "./components/link";
import { drawerStyles } from "./components/drawer";
import { CardComponent } from "./additions/card/Card";
import { CardBodyComponent } from "./additions/card/CardBody";
import { CardHeaderComponent } from "./additions/card/CardHeader";
import { MainPanelComponent } from "./additions/layout/MainPanel";
import { PanelContentComponent } from "./additions/layout/PanelContent";
import { PanelContainerComponent } from "./additions/layout/PanelContainer";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

// import { mode } from "@chakra-ui/theme-tools";
export default extendTheme(
  { breakpoints }, // Breakpoints
  globalStyles,
  buttonStyles, // Button styles
  badgeStyles, // Badge styles
  linkStyles, // Link styles
  drawerStyles, // Sidebar variant for Chakra's drawer
  CardComponent, // Card component
  CardBodyComponent, // Card Body component
  CardHeaderComponent, // Card Header component
  MainPanelComponent, // Main Panel component
  PanelContentComponent, // Panel Content component
  PanelContainerComponent, // Panel Container component
  withProse({
    baseStyle: {
		fonts: {
		  heading: `'Merriweather', serif`, // Elegant, readable serif font for headings
		  body: `'Noto Serif', serif`, // Clean and comfortable for long reading
		},
		p: {
		  fontFamily: `Georgia, "Times New Roman", serif`, // Similar to Medium body text
		  fontSize: "19px", // Medium's comfortable reading size
		  lineHeight: "1.7", // Adequate spacing for readability
		  fontWeight: "500", // Medium-weight font for body
		  color: "#000000", // Soft dark color for text
		},
		h1: {
		  fontFamily: `'Merriweather', serif`, // Standout heading style
		  fontWeight: "700",
		  fontSize: "32px",
		  lineHeight: "1.2",
		},
		h2: {
		  fontFamily: `'Merriweather', serif`,
		  fontWeight: "600",
		  fontSize: "28px",
		  lineHeight: "1.3",
		},
		blockquote: {
		  fontFamily: `'Noto Serif', serif`,
		  fontStyle: "italic",
		  borderLeft: "4px solid #ccc",
		  paddingLeft: "16px",
		  color: "gray.600",
		  fontSize: "18px",
		},
		a: {
		  color: "teal.500",
		  textDecoration: "underline",
		  fontWeight: "500",
		},
	  },
  })
);
