import React from "react";
import { Field } from "formik";
import { Input, FormControl, FormLabel, FormErrorMessage, Textarea, Box } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";

function ChakraInput(props) {
  const { label, name, as, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]} variant="floating">
          {as === "textarea" ? (
            <Textarea id={name} {...rest} {...field} />
          ) : as === "phoneinput" ? (
              <PhoneInput
                onChange={(phone) => form.setFieldValue(name, phone)}
                id={name}
                name="phone"
                value={field.value}
                // label="Phone Number"
                onlyCountries={["in"]}
                country="in"
                inputStyle={{
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid #E2E8F0",
                  paddingLeft: "48px", // Adjusted to avoid overlap with the country code dropdown
                  paddingRight: "12px", // Standard padding on the right
                  fontSize: "sm",
                  ms: "4px",
                  height: "40px", // Adjusted height to match Chakra's Input component
                }}
              />
          ) : (
            <Input id={name} {...rest} {...field} />
          )}
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
}

export default ChakraInput;
