import { mode } from '@chakra-ui/theme-tools';

const activeLabelStyles = {
	transform: "scale(0.85) translateY(-24px)"
  };

export const globalStyles = {
	colors: {
		gray: {
			700: '#1f2733'
		}
	},
	styles: {
		global: (props) => ({
			body: {
				bg: mode('gray.50', 'gray.800')(props),
				fontFamily: "'Roboto', sans-serif"
			},
			html: {
				fontFamily: "'Roboto', sans-serif"
			}
		})
	},
	components: {
		Form: {
		  variants: {
			floating: {
			  container: {
				_focusWithin: {
				  label: {
					...activeLabelStyles
				  }
				},
				"input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
				  ...activeLabelStyles
				},
				label: {
				  top: 0,
				  left: 0,
				  zIndex: 2,
				  position: "absolute",
				  backgroundColor: "white",
				  pointerEvents: "none",
				  mx: 3,
				  px: 1,
				  my: 2,
				  transformOrigin: "left top"
				}
			  }
			}
		  }
		}
	  }
};
