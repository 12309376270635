import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Stack,
  Input,
  Button,
  Heading,
  Divider,
  SimpleGrid,
  FormControl,
  FormLabel,
  Switch,
  GridItem,
  VStack,
  Icon,
  Avatar,
  Select,
  Skeleton,
  Toast,
  useToast,
  InputGroup,
  FormErrorMessage,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import {
  FaUser,
  FaLock,
  FaBell,
  FaShieldAlt,
  FaCalendar,
} from "react-icons/fa";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link as ScrollLink } from "react-scroll";
import styled from "@emotion/styled";
import api from "../../api";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

// Validation schema for Formik
const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
});

const UpdatePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Password is too short - should be 8 characters minimum.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/\d/, "Password must contain at least one number.")
    .matches(
      /[-#!$@£%^&*()_+|~=`{}\[\]:";'<>?,.\/\\ ]/,
      "Password must contain at least one special character."
    )
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Required"),
});

const StyledFormLabel = styled(FormLabel)`
  font-size: 12px;
  color: gray.600;
  font-weight: bold;
  margin-bottom: 1;
`;

const AccountSettings = () => {
  const menuFontSize = { base: "sm", md: "sm" };
  const menuIconSize = { base: "md", md: "lg" };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [userProfile, setUserProfile] = useState(null);
  const toast = useToast();

  useEffect(() => {
    // Get user profile details.
    api.get("/api/profile").then((response) => {
      console.log("User Profile :: ", response.data);
      setUserProfile(response.data);
    });
  }, []);

  const handleSubmit = async (values) => {
    console.log("Handle Submit invoked :: step =>", values);
    values.name = values.firstName + " " + values.lastName;
    try {
      const response = await api.put("/api/profile", values);
      toast({
        title: "Profile Updated",
        description: "Profile details updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error updating profile",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePasswordUpdate = async (values) => {
    console.log("Handle Submit invoked :: step =>", values);
    try {
      const response = await api.put("/api/profile/password", values);
      toast({
        title: "Password Updated",
        description: "Password updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error updating password",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!userProfile) {
    return (
      <Box p={5} mt="80px">
        <VStack spacing={5} align="stretch">
          {/* Profile Details Card Skeleton */}
          <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Skeleton height="40px" width="80%" margin="10px auto" />
            <Skeleton height="20px" width="60%" margin="10px auto" />
            <Skeleton height="20px" width="90%" margin="10px auto" />
          </Box>

          {/* Grid for different settings sections */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
            {/* First Card Skeleton */}
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
              <Skeleton height="30px" width="50%" />
              <Skeleton height="20px" width="90%" marginTop="4" />
              <Skeleton height="20px" width="90%" marginTop="2" />
              <Skeleton height="20px" width="90%" marginTop="2" />
            </Box>

            {/* Second Card Skeleton */}
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
              <Skeleton height="30px" width="50%" />
              <Skeleton height="20px" width="90%" marginTop="4" />
              <Skeleton height="20px" width="90%" marginTop="2" />
              <Skeleton height="20px" width="90%" marginTop="2" />
            </Box>

            {/* Third Card Skeleton */}
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
              <Skeleton height="30px" width="50%" />
              <Skeleton height="20px" width="90%" marginTop="4" />
              <Skeleton height="20px" width="90%" marginTop="2" />
              <Skeleton height="20px" width="90%" marginTop="2" />
            </Box>

            {/* Fourth Card Skeleton */}
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
              <Skeleton height="30px" width="50%" />
              <Skeleton height="20px" width="90%" marginTop="4" />
              <Skeleton height="20px" width="90%" marginTop="2" />
              <Skeleton height="20px" width="90%" marginTop="2" />
            </Box>
          </SimpleGrid>
        </VStack>
      </Box>
    );
  }

  return (
    <Flex direction="row" p={6} spacing={10} mt="80px" flexWrap={"wrap"}>
      {/* Sidebar */}
      <Box
        w={{ base: "100%", md: "20%" }}
        mr={6}
        position="fixed" // Fixed position
        top={20} // Keeps it at the top
        h="100vh" // Full viewport height
        alignSelf="flex-start"
        mt="55px"
        display={{ base: "none", md: "block" }}
      >
        <VStack
          align="flex-start"
          spacing={4}
          borderWidth="1px"
          borderRadius="lg"
          p={4}
          boxShadow="md"
          bg="white"
        >
          <ScrollLink
            to="profileCard"
            smooth={true}
            duration={500}
            offset={-135}
          >
            <Button
              variant="ghost"
              width="100%"
              justifyContent="flex-start"
              fontSize={menuFontSize}
              color="gray.600"
              fontWeight={"normal"}
              gap={2}
              leftIcon={
                <Icon as={FaUser} fontSize={menuIconSize} color="black" />
              }
            >
              Profile
            </Button>
          </ScrollLink>
          <ScrollLink
            to="basicInfoCard"
            smooth={true}
            duration={500}
            offset={-135}
          >
            <Button
              variant="ghost"
              width="100%"
              justifyContent="flex-start"
              fontSize={menuFontSize}
              color="gray.600"
              fontWeight={"normal"}
              gap={2}
              leftIcon={
                <Icon as={FaUser} fontSize={menuIconSize} color="black" />
              }
            >
              Basic Info
            </Button>
          </ScrollLink>
          <ScrollLink
            to="securityCard"
            smooth={true}
            duration={500}
            offset={-135}
          >
            <Button
              variant="ghost"
              width="100%"
              justifyContent="flex-start"
              fontSize={menuFontSize}
              color="gray.600"
              fontWeight={"normal"}
              gap={2}
              leftIcon={
                <Icon as={FaLock} fontSize={menuIconSize} color="black" />
              }
            >
              Security
            </Button>
          </ScrollLink>
          <ScrollLink
            to="preferencesCard"
            smooth={true}
            duration={500}
            offset={-135}
          >
            <Button
              variant="ghost"
              width="100%"
              justifyContent="flex-start"
              fontSize={menuFontSize}
              color="gray.600"
              fontWeight={"normal"}
              gap={2}
              leftIcon={
                <Icon as={FaBell} fontSize={menuIconSize} color="black" />
              }
            >
              Notifications
            </Button>
          </ScrollLink>
          {/* <ScrollLink to="preferencesCard" smooth={true} duration={500} offset={-135}>
            <Button
              variant="ghost"
              width="100%"
              justifyContent="flex-start"
              fontSize={menuFontSize}
              color="gray.600"
              fontWeight={"normal"}
              gap={2}
              leftIcon={<Icon as={FaShieldAlt} fontSize={menuIconSize} color="black"/>}
            >
              Privacy
            </Button>
          </ScrollLink> */}
        </VStack>
      </Box>

      {/* Main Content */}
      <Box
        w={{ base: "100%", md: "73%" }}
        ml={{ base: 0, md: "27%" }}
        spacing={10}
      >
        {/* Profile Details Card */}
        <Box
          id="profileCard"
          borderWidth="1px"
          borderRadius="lg"
          p={6}
          mb={6}
          boxShadow="md"
          display="flex"
          alignItems="center"
          bg="white"
        >
          <Avatar
            size="xl"
            name={userProfile.name}
            mr={6}
            bg="gray.400"
            boxSize="100px"
            borderRadius="12px"
          />
          <Box>
            <Text fontSize="2xl" fontWeight="bold">
              {userProfile.name}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {userProfile.email}
            </Text>
            <Text fontSize="sm" color="gray.500">
              Joined: {userProfile.createdAt}
            </Text>
          </Box>
        </Box>

        {/* Profile Information Form */}
        <Formik
          initialValues={{
            firstName: userProfile?.name?.split(" ")[0],
            lastName: userProfile?.name?.split(" ")[1],
            email: userProfile?.email,
            phone: userProfile?.phone,
            gender: userProfile?.gender,
            dob: userProfile?.dob,
          }}
          validationSchema={ProfileSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              {/* Profile Information Section */}
              <Box
                id="basicInfoCard"
                borderWidth="1px"
                borderRadius="lg"
                p={6}
                mb={6}
                boxShadow="md"
                bg="white"
              >
                <Text fontSize="lg" fontWeight="semibold" mb={8}>
                  Profile Information
                </Text>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <FormControl id="firstName">
                    <StyledFormLabel>First Name</StyledFormLabel>
                    <Field name="firstName" as={Input} placeholder="John" />
                    {errors.firstName && touched.firstName ? (
                      <Text color="red.500" fontSize="xs">
                        {errors.firstName}
                      </Text>
                    ) : null}
                  </FormControl>

                  <FormControl id="lastName">
                    <StyledFormLabel>Last Name</StyledFormLabel>
                    <Field name="lastName" as={Input} placeholder="Doe" />
                    {errors.lastName && touched.lastName ? (
                      <Text color="red.500" fontSize="xs">
                        {errors.lastName}
                      </Text>
                    ) : null}
                  </FormControl>

                  <FormControl id="email">
                    <StyledFormLabel>Email Address</StyledFormLabel>
                    <Field
                      name="email"
                      as={Input}
                      placeholder="john.doe@example.com"
                    />
                    {errors.email && touched.email ? (
                      <Text color="red.500" fontSize="xs">
                        {errors.email}
                      </Text>
                    ) : null}
                  </FormControl>

                  <FormControl id="phone">
                    <StyledFormLabel>Phone Number</StyledFormLabel>
                    <Field name="phone" as={Input} placeholder="+1234567890" />
                    {errors.phone && touched.phone ? (
                      <Text color="red.500" fontSize="xs">
                        {errors.phone}
                      </Text>
                    ) : null}
                  </FormControl>

                  {/* Dropdown for Gender */}
                  <FormControl id="gender">
                    <StyledFormLabel>Gender</StyledFormLabel>
                    <Field
                      name="gender"
                      as={Select}
                      placeholder="Select gender"
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </Field>
                  </FormControl>

                  {/* Date of Birth Field */}
                  <FormControl id="dob">
                    <StyledFormLabel>Date of Birth</StyledFormLabel>
                    <Field name="dob" as={Input} type="date" />
                  </FormControl>
                </SimpleGrid>

                <Flex justify="flex-end" mt={6}>
                  <Button colorScheme="teal" fontSize="sm" type="submit">
                    Update Profile
                  </Button>
                </Flex>
              </Box>
            </Form>
          )}
        </Formik>

        {/* Change Password Section */}
        <Box
          id="securityCard"
          borderWidth="1px"
          borderRadius="lg"
          p={6}
          mb={6}
          boxShadow="md"
          bg="white"
        >
          <Text fontSize="lg" fontWeight="semibold" mb={8}>
            Change Password
          </Text>
          <Formik
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={UpdatePasswordSchema}
            onSubmit={handlePasswordUpdate}
          >
            {({ values, errors, setFieldValue, touched }) => (
              <Form>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
                  <GridItem colSpan={2}>
                    <FormControl id="currentPassword">
                      <StyledFormLabel>Current Password</StyledFormLabel>
                      <Field
                        as={Input}
                        type="password"
                        name="currentPassword"
                        placeholder="Enter current password"
                      />
                      {errors.currentPassword && touched.currentPassword ? (
                        <Text color="red.500" fontSize="xs">
                          {errors.currentPassword}
                        </Text>
                      ) : null}
                    </FormControl>
                  </GridItem>

                  <GridItem colSpan={2}>
                    <FormControl
                      isInvalid={errors.newPassword && touched.newPassword}
                      mb="36px"
                    >
                      <StyledFormLabel>New Password</StyledFormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          id="newPassword"
                          name="newPassword"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your new password"
                        />
                        <InputRightElement>
                          <IconButton
                            aria-label="Toggle Password Visibility"
                            h="1.75rem"
                            size="sm"
                            bg="white"
                            onClick={() => setShowPassword(!showPassword)}
                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          />
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>{errors.newPassword}</FormErrorMessage>
                    </FormControl>
                  </GridItem>

                  {/* Confirm Password Field */}
                  <GridItem colSpan={2}>
                    <FormControl
                      isInvalid={
                        errors.confirmPassword && touched.confirmPassword
                      }
                      mb="36px"
                    >
                      <StyledFormLabel>Confirm New Password</StyledFormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          id="confirmPassword"
                          name="confirmPassword"
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm your new password"
                        />
                        <InputRightElement>
                          <IconButton
                            aria-label="Toggle Confirm Password Visibility"
                            h="1.5rem"
                            size="sm"
                            bg="white"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            icon={
                              showConfirmPassword ? (
                                <ViewOffIcon />
                              ) : (
                                <ViewIcon />
                              )
                            }
                          />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text
                      fontSize="xs"
                      mt="1"
                      color="gray.600"
                      ml="10px"
                      mb="24px"
                    >
                      Your password should have:
                      <Text fontSize="xs" mt="1" color="gray.600" ml="20px">
                        <ul>
                          <li>At least 8 characters</li>
                          <li>At least one uppercase letter</li>
                          <li>At least one special character</li>
                          <li>At least one numeric digit</li>
                        </ul>
                      </Text>
                    </Text>
                  </GridItem>
                </SimpleGrid>

                <Flex justify="flex-end" mt={6}>
                  <Button colorScheme="teal" fontSize="sm" type="submit">
                    Change Password
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>

        {/* Notifications and Preferences */}
        <Box
          id="preferencesCard"
          borderWidth="1px"
          borderRadius="lg"
          p={6}
          boxShadow="md"
          bg="white"
        >
          <Text fontSize="lg" fontWeight="semibold" mb={8}>
            Account Preferences
          </Text>
          <Stack spacing={6}>
            <FormControl display="flex" alignItems="center">
              <StyledFormLabel
                fontSize="sm"
                color="gray.600"
                htmlFor="notifications"
                mb="0"
              >
                Email Notifications
              </StyledFormLabel>
              <Switch id="notifications" isChecked />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <StyledFormLabel
                fontSize="sm"
                color="gray.600"
                htmlFor="notifications"
                mb="0"
              >
                Whatsapp Notifications
              </StyledFormLabel>
              <Switch id="notifications" isChecked />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <StyledFormLabel
                fontSize="sm"
                color="gray.600"
                htmlFor="newsletter"
                mb="0"
              >
                Subscribe to Newsletter
              </StyledFormLabel>
              <Switch id="newsletter" />
            </FormControl>

            <Flex justify="flex-end" mt={6}>
              <Button colorScheme="teal" fontSize="sm">
                Save Preferences
              </Button>
            </Flex>
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};

export default AccountSettings;
