// src/components/Hero.js
import heroImage from "../../assets/img/Hero-section.jpeg";
import React, { useEffect } from "react";
import { Box, Flex, Text, Button, Image, Heading } from "@chakra-ui/react";
import { useAuthContext } from "../../hooks/useAuthContext";
import api from "../../api";
import Carousel from "./Carousel";

const Hero = ({ scrollToInfoSection }) => {
  const { user } = useAuthContext();

  useEffect(() => {
    const recordVisit = async () => {
      try {
        // Fire and forget.. no need to await
        api.post("/api/visitors/visit");
      } catch (error) {
        // Do nothing if this errors out.. it's ok.. try logging later.
        //console.error("Error recording visit:", error);
      }
    };

    recordVisit();
  }, []);

  // const headingColor1 =  "#FF4500"; //
  // const headingColor2 = "#FF5E0E";
  const headingColor = "#FB4F14";

  // const headingColor4 = "orange.400";

  return (
    <Flex
      alignItems="center"
      justifyContent="space-around"
      bg="gray.50" // Optional background color or gradient
      flexWrap={"wrap"}
      mt="10px"
      mb="10px"
    >
      {/* Left Side - Text Section */}
      <Box maxWidth={{ base: "95%", md: "50%" }} align="center" mt={{ base: "50px", md: "0px" }}>
        <Flex gap={"0px"} direction={"column"} mb="30px">
          <Heading as="h1" size={{ base: "xl", md: "2xl" }} fontWeight={"semibold"}>
            Get{" "}
            <Text color={headingColor} display="inline">
              Medically Qualified Professionals
            </Text>
          </Heading>
          <Heading as="h1" size={{ base: "lg", md: "xl" }} fontWeight={"semibold"}>
            - for Hospital / Clinic visits
          </Heading>
          {/* <Heading as="h1" size="xl" mb="30px" color="teal.500" fontWeight={"semibold"}>
            at your doorstep
          </Heading> */}
        </Flex>
        <Text fontSize={{ base: "md", md: "lg" }} color="gray.500" fontWeight={"550"}>
          We deliver personalized care through a trusted network of medically trained companions, ensuring the best
          support for your loved ones.
        </Text>
        {/* <Input placeholder="Enter pickup location" />
              <Input placeholder="Enter Clinic or Hospital details" /> */}
        <Text fontSize="md" fontWeight="bold" mb={4}>
          ( Dedicated to serving the Elderly/Disabled community )
        </Text>
        <Button
          type="submit"
          bg="teal.600"
          color="white"
          width="150px"
          onClick={() => (window.location.href = "/user/dashboard")}
        >
          {user ? "Go To Dashboard" : "Book Now"}
        </Button>
        <Button
          type="submit"
          bg="teal.600"
          color="white"
          width="150px"
          onClick={scrollToInfoSection}
          ml="10px"
        >
          Learn More
        </Button>
        {/* <Text fontSize="5xl" fontWeight="bold" mb={4}>
        Move the way you want
      </Text>
      <Text fontSize="xl" mb={8}>
        Get where you’re going with Uber.
      </Text>
      <Button size="lg" colorScheme="blackAlpha">
        Sign up to ride
      </Button> */}
      </Box>

      {/* Right Side - Image Section */}
      <Box justify="center" mt={{ base: "20px", lg: "0px" }} maxWidth={{ base: "100%", lg: "40%" }}>
        <Carousel />
        {/* <Image
          src={processCollageWhite} // Replace with the actual image URL
          alt="vKutumb Hero"
          maxWidth={{ base: "100%", lg: "600px" }} // Constrain the maximum width of the image
          objectFit="contain"
        /> */}
      </Box>
    </Flex>
  );
};

export default Hero;
