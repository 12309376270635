// Chakra imports
import {
  Card,
  CardHeader,
  Flex,
  Icon,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Avatar,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Progress,
  Td,
  ModalCloseButton,
} from "@chakra-ui/react";
// Custom components
import React, { useState } from "react";
import ReportModal from "../../components/ReportModal";
import { HiOutlineDocumentReport } from "react-icons/hi";
import BookVisit from "./VisitRoot";

const RecentVisits = ({ bookingDataHandler }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const title = "Recent Visits";
  const captions = ["Status", "Relative Name", "Doctor", "Date/Time", "Reports"];

  const { onOpen, isOpen, onClose } = useDisclosure();
  const [reportData, setReportData] = useState("");
  const [detailsOpen, setDetailsOpen] = useState(false);

  const data = bookingDataHandler.bookings;

  console.log("data :: ", data);

  const statusMap = {
    "Pending Payment": [10, "red.500"],
    "Rescheduled": [20, "teal.500"],
    "Booking Registered": [20, "teal.500"],
    "Report generated": [90, "green.400"],
    "Pickup done": [40, "blue.500"],
    "Reached Hostpital": [60, "blue"],
    "Drop done": [80, "green.100"],
    "Done": [100, "green.600"],
  };

  function getStatusInfo(status) {
    var progression = 0;
    var statusColor = "teal.300";
    console.log(status);
    if (status) {
      if (statusMap[status]) {
        progression = statusMap[status][0];
        statusColor = statusMap[status][1];
      }
    }
    return { progression, statusColor };
  }

  function getFormattedTime(time) {
    if (time) {
      let [hours, minutes] = time.split(":");
      const modifier = hours >= 12 ? "PM" : "AM";

      // Convert hours from 24-hour to 12-hour format
      hours = hours % 12 || 12; // If hours = 0, set it to 12 (midnight)

      return `${hours}:${minutes} ${modifier}`;
    }
  }

  function getFormattedDate(date) {
    if (date) {
      return new Date(date).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    }
  }

  function onReportClick(event, row) {
    event.stopPropagation();
    console.log("Report clicked for bookingID ::", row.report);
    row.report["visitDate"] = row.date;
    row.report["patientName"] = row.relative.name;
    row.report["patientAge"] = row.relative.Age;
    row.report["patientGender"] = row.relative.gender;
    row.report["patientContact"] = row.relative.phoneNumber;
    row.report["visitDate"] = row.date;

    setReportData(row.report);
    onOpen();
    // Fetch Report and show.
  }
  const openModal = (booking) => {
    console.log("IsOpen value in openModal: ", isOpen);
    if (isOpen) return;
    console.log("Open Modal :: ", booking);
    bookingDataHandler.setSelectedBooking(booking.bookingId);
    setDetailsOpen(true);
  };

  return (
    <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p="12px 0px 28px 0px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
            {title}
          </Text>
          <Flex align="center">
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              <Text fontWeight="bold" as="span">
                Doctor/Hostpital visits in recent past
              </Text>
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <Table variant="simple" color={textColor}>
        <Thead>
          <Tr my=".8rem" ps="0px">
            {captions.map((caption, idx) => {
              return (
                <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : null}>
                  {caption}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            Object.keys(data).map((rowKey, index) => {
              // console.log("DAta key for key ::",rowKey, ", data :: ", data[rowKey]);
              var row = data[rowKey];
              var formattedDate = getFormattedDate(row.date);
              var formattedTime = getFormattedTime(row.time);
              var statusInfo = getStatusInfo(row.status);
              return (
                <Tr key={row.bookingId} onClick={() => openModal(row)}>
                  {/* onClick={() => openModal(row)} */}
                  <Td>
                    <Flex direction="column">
                      <Text
                        fontSize="xs"
                        color={statusInfo.statusColor}
                        fontWeight="bold"
                        pb=".2rem"
                      >{`${row.status}`}</Text>
                      <Progress
                        colorScheme={statusInfo.progression === 100 ? "teal" : "cyan"}
                        size="xs"
                        value={statusInfo.progression}
                        borderRadius="15px"
                      />
                    </Flex>
                  </Td>
                  <Td minWidth={{ sm: "100px" }} pl="0px">
                    <Flex align="center" py=".8rem" minWidth="100%" flexWrap="wrap">
                      <Text fontSize="15" color={textColor} fontWeight="520">
                        {row.relative.name} ({row.relative.relation})
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Text fontSize="15" color={textColor} fontWeight="520" pb=".5rem">
                      {row.doctorName}
                    </Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Text fontSize="15" color={textColor} fontWeight="520" pb=".5rem">
                        {formattedDate} /
                      </Text>
                      <Text fontSize="12" color={textColor} fontWeight="520" pb=".5rem">
                        {formattedTime}
                      </Text>
                    </HStack>
                  </Td>
                  <Td>
                    <Text fontSize="15" color={textColor} fontWeight="520" pb=".5rem">
                      <Icon
                        as={HiOutlineDocumentReport}
                        color={row.status === "Report generated" ? "teal.400" : "gray.300"}
                        zIndex="1"
                        w={8}
                        h={8}
                        pe="3px"
                        cursor="pointer"
                        onClick={row.status === "Report generated" ? (event) => onReportClick(event, row) : undefined}
                      />
                    </Text>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      {isOpen && <ReportModal isOpen={isOpen} onClose={onClose} reportData={reportData} />}
      {detailsOpen && (
        <BookVisit
          detailsOpen={detailsOpen}
          onClose={() => setDetailsOpen(false)}
          bookingDataHandler={bookingDataHandler}
        />

        // <BookingDetailsModal
        //   isOpen={detailsOpen}
        //   onClose={() => setDetailsOpen(false)}
        //   bookingData={selectedBooking}
        //   // onEdit={handleEdit}
        //   // onReschedule={handleReschedule}
        //   // onPay={handlePay}
        // />
      )}
    </Card>
  );
};

export default RecentVisits;
