// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Switch,
  Text,
  useColorModeValue,
  useToast,
  Link as ChakraLink,
  LinkProps,
  FormErrorMessage,
  Tooltip,
  Checkbox,
  useDisclosure,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "../../assets/img/bgSignup.jpeg";
import React, { useRef, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useLogin } from "hooks/useLogin";
import RecaptchaComponent from "components/RecaptchaComponent";
import PhoneInputField from "../../components/PhoneInputField";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import Header from "../../pages/Home/Header";
import TermsConditionsModal from "./termsConditions";
import PasswordConfirm from "./PasswordConfirm";

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().required("Phone number is required"),
  // phone: Yup.string()
  //   .matches(/^[0-9]+$/, "Must be only digits")
  //   .min(10, "Must be at least 10 digits")
  //   .required("Required"),
  password: Yup.string().min(8, "Password too Short!").required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

function SignUp() {
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");

  const toast = useToast();
  const { signup, error, isLoading } = useLogin();
  const initialValues = {
    token: "",
    name: "",
    email: "",
    phone: "",
    password: "",
  };
  const recaptchaRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAccepted, setIsAccepted] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    if (isCheckboxChecked) {
      setIsCheckboxChecked(false);
    } else {
      onOpen();
    }
  };

  const handleAccept = () => {
    setIsAccepted(true);
    setIsCheckboxChecked(true);
    onClose();
  };

  const handleReject = (event) => {
    setIsAccepted(false);
    setIsCheckboxChecked(false);
    onClose();
  };

  async function handleSubmit(values, submitProps) {
    if (!isAccepted) {
      toast({
        title: "Please accept terms and conditions",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      })
    }
    var signUpError = await signup(values);
    if (signUpError) {
      toast({
        title: "Signup failed.",
        description: signUpError.response.data.error,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    recaptchaRef.current.reset();
  }

  return (
    <>
      <Header />
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        overflow="hidden"
      >
        <Box
          position="absolute"
          minH={{ base: "70vh", md: "50vh" }}
          w={{ md: "calc(100vw - 50px)" }}
          borderRadius={{ md: "15px" }}
          left="0"
          right="0"
          bgRepeat="no-repeat"
          overflow="hidden"
          zIndex="-1"
          top="0"
          bgImage={BgSignUp}
          bgSize="cover"
          mx={{ md: "auto" }}
          mt={{ md: "64px" }}
        ></Box>
        <Flex
          direction="column"
          textAlign="center"
          justifyContent="center"
          align="center"
          mt="6.5rem"
          mb="30px"
        >
          <Text fontSize="4xl" color="black" fontWeight="bold">
            Welcome!
          </Text>
          <Text
            fontSize="md"
            color="black"
            fontWeight="normal"
            mt="10px"
            mb="26px"
            w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}
          >
            Kindly sign up to continue. Create new account for free.
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
          <Flex
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="15px"
            p="40px"
            mx={{ base: "100px" }}
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Text
              fontSize="xl"
              color={textColor}
              fontWeight="bold"
              textAlign="center"
              mb="22px"
            >
              Register your account
            </Text>
            {/* <HStack spacing="15px" justify="center" mb="22px">
            <Flex
              justify="center"
              align="center"
              w="75px"
              h="75px"
              borderRadius="15px"
              border="1px solid lightgray"
              cursor="pointer"
              transition="all .25s ease"
              _hover={{ filter: "brightness(120%)", bg: bgIcons }}
            >
              <ChakraLink href="#">
                <Icon as={FaFacebook} w="30px" h="30px" _hover={{ filter: "brightness(120%)" }} />
              </ChakraLink>
            </Flex>
            <Flex
              justify="center"
              align="center"
              w="75px"
              h="75px"
              borderRadius="15px"
              border="1px solid lightgray"
              cursor="pointer"
              transition="all .25s ease"
              _hover={{ filter: "brightness(120%)", bg: bgIcons }}
            >
              <ChakraLink href="#">
                <Icon as={FaApple} w="30px" h="30px" _hover={{ filter: "brightness(120%)" }} />
              </ChakraLink>
            </Flex>
            <Flex
              justify="center"
              align="center"
              w="75px"
              h="75px"
              borderRadius="15px"
              border="1px solid lightgray"
              cursor="pointer"
              transition="all .25s ease"
              _hover={{ filter: "brightness(120%)", bg: bgIcons }}
            >
              <ChakraLink href="#">
                <Icon as={FaGoogle} w="30px" h="30px" _hover={{ filter: "brightness(120%)" }} />
              </ChakraLink>
            </Flex>
          </HStack>
          <Text fontSize="lg" color="gray.400" fontWeight="bold" textAlign="center" mb="22px">
            or
          </Text> */}
            <Formik
              initialValues={initialValues}
              validationSchema={SignupSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <FormControl
                    isInvalid={errors.name && touched.name}
                    mb="36px"
                  >
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Name
                    </FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name="name"
                      type="test"
                      fontSize="sm"
                      ms="4px"
                      borderRadius="15px"
                      placeholder="Your full name"
                      size="lg"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors.email && touched.email}
                    mb="36px"
                  >
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Email
                    </FormLabel>
                    <Field
                      as={Input}
                      id="email"
                      name="email"
                      type="email"
                      fontSize="sm"
                      ms="4px"
                      borderRadius="15px"
                      placeholder="Your email address"
                      size="lg"
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>
                  <Box mb="36px">
                    <PhoneInputField name="phone" label="Phone Number" />
                  </Box>

                  <PasswordConfirm errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} />

                  {/* <FormControl
                    isInvalid={errors.password && touched.password}
                    mb="36px"
                  >
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Password
                    </FormLabel>

                    <Field
                      as={Input}
                      id="password"
                      name="password"
                      fontSize="sm"
                      ms="4px"
                      borderRadius="15px"
                      type="password"
                      placeholder="Your password"
                      size="lg"
                    />

                    <Text fontSize="xs" mt="1" color="gray.400" ml="10px">
                      Your password should have:
                      <Text fontSize="xs" mt="1" color="gray.400" ml="20px">
                        <ul>
                          <li>At least 8 characters long</li>
                          <li>At least one uppercase letter</li>
                          <li>At least one special character</li>
                          <li>At least one numeric digit</li>
                        </ul>
                      </Text>
                    </Text>
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl> */}
                  <FormControl display="flex" alignItems="center" mb="24px">
                    <Switch id="remember-login" colorScheme="teal" me="10px" />
                    <FormLabel
                      htmlFor="remember-login"
                      mb="0"
                      fontWeight="normal"
                    >
                      Remember me
                    </FormLabel>
                  </FormControl>
                  <RecaptchaComponent
                    ref={recaptchaRef}
                    onChange={(val) => {
                      setFieldValue("token", val);
                    }}
                  />
                  <Checkbox isChecked={isCheckboxChecked} onChange={handleCheckboxChange} mt="24px">
                    I agree to the terms and conditions
                  </Checkbox>
                  <Button
                    type="submit"
                    bg="teal.300"
                    fontSize="10px"
                    color="white"
                    fontWeight="bold"
                    w="100%"
                    h="45"
                    mt="14px"
                    mb="24px"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.700",
                    }}
                    isDisabled={!values.token || !isCheckboxChecked}
                  >
                    SIGN UP
                  </Button>
                </Form>
              )}
            </Formik>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Already have an account?
                <ChakraLink
                  color={titleColor}
                  as={ReactRouterLink}
                  ms="5px"
                  href="#"
                  fontWeight="bold"
                  to="/login"
                >
                  Sign In
                </ChakraLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <TermsConditionsModal
        isOpen={isOpen}
        onClose={onClose}
        handleAccept={handleAccept}
        handleReject={handleReject}
      />
    </>
  );
}

export default SignUp;
