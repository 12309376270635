import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
  const { setUser } = useAuthContext()

  const logout = () => {
    // remove user from storage
    localStorage.removeItem('user')

    // dispatch logout action
    setUser(null)
  }

  return { logout }
}