// RequestResetPassword.js
import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  Flex,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import api from "../../api";
import { useNavigate } from "react-router-dom";

const RequestResetPassword = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    // Call backend API to send OTP and reset link
    try {
      const res = await api.post("/api/user/forgot-password", {email : values.email});
      console.log("Forgot-password response ::", res);
      const { token } = res.data;
      toast({
        title: "Reset Link Sent",
        description:
          "If we have an account registered with the provided email, a reset password link and OTP have been sent to your email. If you do not receive that email, then please contact support.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate('/validate-token', { state: { email : values.email, token } });
    } catch (error) {
      toast({
        title: "Failed to send reset link.",
        description: error.response.data.error + ". Please contact vKutumb Support.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setSubmitting(false);
  };

  return (
    <Flex direction="column" align="center" justify="center" mt="1rem">
      <Box bg="white" p={6} rounded="md" shadow="md">
        <Text fontSize="3xl" mb={4} textColor="gray.500">
          Reset Password
        </Text>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .required("Required"),
          })}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormControl isInvalid={false}>
                <FormLabel>
                  Enter your email address below, and we will send you a link to
                  reset your password
                </FormLabel>
                <Field
                  name="email"
                  as={Input}
                  type="email"
                  placeholder="Enter your email"
                />
                <FormErrorMessage>{}</FormErrorMessage>
              </FormControl>
              <Button
                mt={4}
                type="submit"
                isLoading={isSubmitting}
                colorScheme="teal"
              >
                Send Reset Link
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default RequestResetPassword;

// If we have recognised your account, an email will be sent to allow you to reset your password. If you do not receive that email, then please contact support.

// If you have any further questions, please contact support.
