import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Box, FormControl, FormLabel, Input, Button, FormErrorMessage, Text, VStack, HStack, useToast, Image } from "@chakra-ui/react";
import * as Yup from "yup";
import api from "../../api";


const PaymentForm = ({ handleClose, booking, bookingDataHandler, setActiveStep }) => {
  const validationSchema = Yup.object({
    payRef: Yup.string()
      .matches(/^\d{12}$/, "UPI Reference Number must be exactly 12 digits")
      .required("UPI Reference Number is required"),
  });
  const toast = useToast();
  const [qrElement, setQRElement] = useState("");

  const relative = booking? booking.relative : bookingDataHandler.getCurrentRelative();


  useEffect(() => {
    bookingDataHandler.getQRCode(setQRElement, toast, relative)
  }, []);


  async function handlePaymentStep(values) {
    // Update the payement reference number.
    console.log("app details step 2 : ", booking);
    try {
      var updates = { payReference: values.payRef, status: "Booking Registered" };
      var res = await api.put("/api/bookings/" + booking.bookingId, updates);

      booking["payReference"] = values.payRef;
      booking["status"] = "Booking Registered";

      bookingDataHandler.addUpdateBooking(booking);
      if (bookingDataHandler.revalidator) bookingDataHandler.revalidator.revalidate();
    } catch (error) {
      console.log("error", error);
      toast({
        title: "Error storing payment reference",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setActiveStep(2);
  }

  return (
    <Formik initialValues={{ payRef: "" }} validationSchema={validationSchema} onSubmit={handlePaymentStep}>
      {({ errors, touched, isValid, dirty }) => (
        <Form>
          <VStack spacing={4} mb="30px">
            <Text>Please make the payment on the UPI link.</Text>
            {qrElement && <Image size="lg" src={qrElement} alt="QR Code" />}
          </VStack>
          <FormControl isInvalid={errors.payRef && touched.payRef}>
            <FormLabel htmlFor="payRef">UPI Reference Number (12 digit number)</FormLabel>
            <Field as={Input} id="payRef" name="payRef" type="number" placeholder="Enter the UPI reference number" />
            <FormErrorMessage>{errors.payRef}</FormErrorMessage>
          </FormControl>
          <HStack justify="space-between" mt={6} w="100%">
            <Button onClick={handleClose} variant="outline" colorScheme={"red"}>
              Close
            </Button>
            <Button type="submit" colorScheme="teal" isDisabled={!(isValid && dirty)}>
              Verify Payment
            </Button>
          </HStack>
        </Form>
      )}
    </Formik>
  );
};

export default PaymentForm;
