import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Text,
  Divider,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Modal,
  ModalBody,
  Box,
  Center,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import BookVisitStepper from "./VisitStepper";
import VisitForm from "./VisitForm";
import PaymentForm from "./PaymentForm";
import Confirmation from "./ConfirmationStep";
import * as Yup from "yup";

const BookVisit = ({ bookingDataHandler, onClose, detailsOpen }) => {
  const [activeStep, setActiveStep] = useState(0);

  const currentBooking = bookingDataHandler.getCurrentBooking();

  const editableStatuses = ["Pending Payment", "Booking Registered", "Rescheduled"];
  const canEdit = currentBooking ? editableStatuses.includes(currentBooking.status) : true;

  const steps = [
    {
      title: currentBooking ? (canEdit ? "Update Visit Details" : "Visit Details") : "Book a vMitra Visit",
      description: "Enter visit details",
      component: (
        <VisitForm
          canEdit={true}
          booking={currentBooking}
          setActiveStep={setActiveStep}
          bookingDataHandler={bookingDataHandler}
          handleClose={handleClose}
        />
      ),
    },
    {
      title: "Payment",
      description: "Complete payment for visit",
      component: (
        <PaymentForm
          booking={bookingDataHandler.getCurrentBooking()}
          setActiveStep={setActiveStep}
          handleClose={handleClose}
          bookingDataHandler={bookingDataHandler}
        />
      ),
    },
    { title: "Confirmation", description: "Booking Registered", component: <Confirmation handleClose={handleClose} /> },
  ];

  if ((activeStep === 1 || activeStep===0) && (currentBooking ? currentBooking.status !== "Pending Payment" : false)) {
    steps.splice(1, 1);
  }

  function handleClose() {
    setActiveStep(0);
    bookingDataHandler.clearCurrentState();
    if (onClose) onClose();
  }

  return (
    <Modal
      isOpen={detailsOpen}
      onClose={handleClose}
      size="3xl"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered={true}
    >
      <ModalOverlay />

      <ModalContent borderRadius="md" overflow="hidden">
        <ModalHeader bgColor="teal.700" mb="20px" minH="100px">
          <VStack w="100%">
            {/* <Center w="100%"> */}
            <Text
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
              color="white"
              alignSelf={"center"}
              mb="20px"
              mt="40px"
            >
              {steps[activeStep].title}
            </Text>
            {/* </Center> */}
            <BookVisitStepper activeStep={activeStep} steps={steps} />
          </VStack>
        </ModalHeader>
        <ModalCloseButton color="white" size="lg" borderColor="white" borderWidth={"1px"} />
        <ModalBody p="5%">
          {/* <Divider mt="20px" mb="20px" /> */}
          {steps[activeStep].component}
        </ModalBody>
        <ModalFooter bg="white"></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookVisit;
