import React from "react";
import { VStack, Text, Button } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const Confirmation = ({ handleClose }) => {
  return (
    <VStack>
      <Text color="teal.800" size="lg">
        Thank you! Your vMitra request is registered. We will confirm the payment and assign the personnel soon.
      </Text>
      <Button leftIcon={<CheckIcon />} onClick={handleClose}>
        Done
      </Button>
    </VStack>
  );
};

export default Confirmation;

