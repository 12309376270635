import { useState } from 'react';
import { Box, Input, Button, Heading, Text } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';

const ValidateToken = () => {
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { email, token: generatedToken } = location.state;

  const handleSubmit = (e) => {
    console.log("Token :: ", generatedToken);
    e.preventDefault();
    if (token === generatedToken) {
      navigate(`/reset-password/${token}`, { state: { email } });
    } else {
      alert('Invalid token, please check your email.');
    }
  };

  return (
    <Box p={6} maxW="400px" mx="auto" mt="2rem" rounded="md" shadow="md" bg="white">
      <Text fontSize="3xl" mb={4} textColor="gray.500">Enter Token</Text>
      <Text mb={4}>Check your email for the reset token.</Text>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder="Enter token"
          mb={3}
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
        <Button colorScheme="teal" type="submit" width="full">Verify Token</Button>
      </form>
    </Box>
  );
};

export default ValidateToken;
