const { Icon } = require("@chakra-ui/react");
const { TfiPulse } = require("react-icons/tfi");
const { RiDashboardFill } = require("react-icons/ri");
const { FaPeopleLine, FaPeopleRoof } = require("react-icons/fa6");
const { FaRegAddressCard } = require("react-icons/fa");

export const userRoutes = [
  {
    name: "Dashboard",
    layout: "",
    path: "/user/dashboard",
    icon: <Icon as={RiDashboardFill} w="20px" h="20px" />,
    role: ["user", "admin"],
    // component: Dashboard
  },
  {
    name: "Manage Relatives",
    layout: "",
    path: "/user/manageRelatives",
    icon: <Icon as={FaPeopleRoof} w="20px" h="20px" />,
    //   component: <Users />}
  },
  {
    name: "Profile",
    layout: "",
    path: "/user/profile",
    icon: <Icon as={FaRegAddressCard} w="20px" h="20px" />,
    //   component: <Users />}
  },

  // {
  //   name: "Medical Form - temporary",
  //   layout: "",
  //   path: "/medicalform",
  //   icon: <Icon as={TfiPulse} w="20px" h="20px" />,
  //   // component: Dashboard
  // },
  //MedicalForm
];

export const adminRoutes = [
  {
    name: "Create Blog",
    layout: "",
    path: "/user/editBlog",
    icon: <Icon as={TfiPulse} w="20px" h="20px" />,
    role: ["admin"],
    //   component: <Users />}
  },
  {
    name: "Create Report",
    layout: "",
    path: "/user/createReport",
    icon: <Icon as={TfiPulse} w="20px" h="20px" />,
    role: ["admin"],
    //   component: <Users />}
  },
  {
    name: "WebSite Visitor Report",
    layout: "",
    path: "/user/websitevisitor",
    icon: <Icon as={TfiPulse} w="20px" h="20px" />,
    role: ["admin"],
    //   component: <Users />}
  },
  {
    name: "Manage Bookings",
    layout: "",
    path: "/user/allbookings",
    icon: <Icon as={TfiPulse} w="20px" h="20px" />,
    role: ["admin"],
    //   component: <Users />}
  },
];
