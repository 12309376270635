// src/pages/ContactUs.js
import React, { useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Stack,
  Link,
  useToast,
  Spinner,
  HStack,
} from "@chakra-ui/react";
import Header from "./Header";
import { FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { Field, Form, Formik } from "formik";
import api from "../../api";
import ChakraInput from "../../components/ChInput";
import RecaptchaComponent from "../../components/RecaptchaComponent";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email format")
    .required("Required"),
  message: Yup.string().required("Required"),
});

const ContactUs = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef(null);

  async function onSubmit(values, { resetForm }) {
    setLoading(true);
    // console.log(values);
    try {
      const res = await api.post("/api/util/contact", values);
      toast({
        title: "Thank You for Reaching Out! ",
        description: "Your message has been received, and our team will get back to you shortly.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top", // Place the toast at the top
        containerStyle: {
          mt: "20vh", // Adjust this value to center it vertically
        },
      });
      resetForm();
      setLoading(false);
    } catch (error) {
      toast({
        title: "Oops, Something Went Wrong!",
        description: "We couldn't process your message. Please try again later or contact us directly.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top", // Place the toast at the top
        containerStyle: {
          mt: "20vh", // Adjust this value to center it vertically
        },
      });
    }
  }

  if (loading) {
    return (
      <Box textAlign="center" mt="8">
        <Flex direction="column" align="center" justify="center" height="500px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="teal.500" size="xl" />
          <Text mt="4" fontSize="lg">
            Sending Message, please wait...
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      <Header />
      <Box paddingY="16" bg="gray.50">
        <Flex direction="column" align="center" maxWidth="800px" margin="auto" paddingX="8">
          {/* Contact Information */}
          <Box mb="12" textAlign="center">
            <Text fontSize="4xl" fontWeight="bold" mb="4">
              Contact Us
            </Text>
            <Text fontSize="lg" mb="8">
              We’d love to hear from you. Please fill out the form below or reach out to us directly.
            </Text>

            {/* Contact Details */}
            <Flex direction={{ base: "column", md: "row" }} justify="center" spacing="8">
              <Box flex="1" maxWidth="400px" mb={{ base: "8", md: "0" }}>
                <Text fontSize="lg" fontWeight="bold" mb="2">
                  Email:
                </Text>
                <Text>
                  Email us at:{" "}
                  <Link href="mailto:support@vKutumb.com" color="blue.500">
                    support@vKutumb.com
                  </Link>
                </Text>
              </Box>
              <Box flex="1" maxWidth="400px">
                <Text fontSize="lg" fontWeight="bold" mb="2">
                  Phone:
                </Text>
                <Text>
                  Call us at:{" "}
                  <Link href="tel:+91 8010630611" color="blue.500">
                    +91 80106-30611
                  </Link>
                </Text>
              </Box>
            </Flex>

            {/* Social Links */}
            <Flex direction="column" align="center" mt="8">
              <Text fontSize="lg" fontWeight="bold" mb="4">
                Or connect with us on:
              </Text>
              <Flex direction={{ base: "column", md: "row" }} justify="center" spacing="4">
                {/* WhatsApp Link  918010630611*/}
                <Link
                  href="https://wa.me/918010630611"
                  isExternal
                  _hover={{ color: "green.500" }}
                  mb={{ base: "4", md: "0" }}
                  mr={{ md: "4" }}
                >
                  <Flex align="center">
                    <FaWhatsapp size="24px" />
                    <Text ml="2" fontSize="lg" color="green.400">
                      WhatsApp
                    </Text>
                  </Flex>
                </Link>

                {/* Telegram Link */}
                <Link href="https://t.me/vkutumb" isExternal _hover={{ color: "blue.500" }}>
                  <Flex align="center">
                    <FaTelegramPlane size="24px" />
                    <Text ml="2" fontSize="lg" color="blue.400">
                      Telegram
                    </Text>
                  </Flex>
                </Link>
              </Flex>
            </Flex>
          </Box>
          <Box width="100%" maxWidth="600px" bg="white" p="8" borderRadius="md" boxShadow="md">
            <Formik
              initialValues={{ name: "", email: "", message: "" }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  {/* Contact Form */}

                  <Text fontSize="2xl" fontWeight="bold" mb="6" textAlign="center">
                    Send Us a Message
                  </Text>
                  <Stack spacing="6">
                    <ChakraInput name="name" type="text" placeholder="Your Name" label="Your Name" />
                    <ChakraInput name="email" type="email" placeholder="Your Email" label="Your Email" />
                    <ChakraInput
                      as="textarea"
                      name="message"
                      placeholder="Your Message"
                      rows="6"
                      label="Your Message"
                    />
                    <HStack justify="center">
                      <RecaptchaComponent
                        ref={recaptchaRef}
                        onChange={(val) => {
                          setFieldValue("token", val);
                        }}
                      />
                    </HStack>
                    <Button colorScheme="teal" size="lg" type="submit" isDisabled={!values.token}>
                      Send Message
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>

          {/* Optional: Map Section */}
          {/* Uncomment and replace with your map URL if needed */}
          {/* <Box mt="16" width="100%" maxWidth="1200px">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.058847669654!2d-122.39868268468203!3d37.791552979757166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808a1d1d5c63%3A0x477bfadf9cc4f12b!2sUber!5e0!3m2!1sen!2sus!4v1632464008982!5m2!1sen!2sus"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Map"
          ></iframe>
        </Box> */}
        </Flex>
      </Box>
    </Box>
  );
};

export default ContactUs;
