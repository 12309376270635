// Chakra imports
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import api from "../../api";

// Custom components
import RelativesTableRow from "./RelativesTableRow";
import React, { useEffect, useState } from "react";
import ConfirmActionDialog from "./ConfirmActionDialog";
import RelativeDetailsForm from "./RelativeDetailsModal";
import { useRevalidator } from "react-router-dom";

const Relatives = ({ data }) => {
  const textColor = useColorModeValue("gray.700", "white");

  const [relatives, setRelatives] = useState([]);
  const [editingRelative, setEditingRelative] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [selectedRelativeId, setSelectedRelativeId] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const revalidator = useRevalidator();

  const title = "Manage Relatives";
  const captions = ["Relative", "Address", "Relation", "Actions"];

  useEffect(() => {
    const fetchRelatives = async () => {
      try {
        const response = await api.get("/api/relatives/");
        setRelatives(response.data);
      } catch (error) {
        toast({ title: "Error fetching relatives.", status: "error" });
      }
    };
    fetchRelatives();
  }, []);

  const handleDelete = async (id) => {
    setCurrentAction("Delete");
    setSelectedRelativeId(id);
    setIsDialogOpen(true);
  };

  const handleUpdate = async (relative) => {
    setCurrentAction("Update");
    setEditingRelative(relative);
    setIsDialogOpen(true);
  };

  const handleEdit = async (relative) => {
    setCurrentAction("Update");
    setEditingRelative(relative);
    setSelectedRelativeId(relative._id);
    onOpen();
  };

  const handleConfirmAction = async () => {
    if (currentAction === "Delete") {
      try {
        await api.delete(`/api/relatives/${selectedRelativeId}`);
        setRelatives(relatives.filter((relative) => relative._id !== selectedRelativeId));
        toast({ title: "Relative deleted successfully.", status: "success" });
      } catch (error) {
        toast({ title: "Error deleting relative.", status: "error" });
      }
    } else if (currentAction === "Update") {
      // No action needed here since the form handles updates directly
      try {
        await api.put(`/api/relatives/${selectedRelativeId}`, editingRelative);
        toast({ title: "Relative updated successfully.", status: "success" });
        setRelatives(relatives.map((relative) => (relative._id === selectedRelativeId ? editingRelative : relative)));
      } catch (error) {
        toast({ title: "Error updating relative.", status: "error" });
      }
    }
    setIsDialogOpen(false);
    onClose();
    setEditingRelative(null);
    setSelectedRelativeId(null);
  };

  const handleSuccess = () => {
    // TODO : remove this and do a better job of not fetching again..
    //by managing the state using an object mapping id:relative thus not having to fetch again
    setEditingRelative(null);
    const fetchRelatives = async () => {
      const response = await api.get("/api/relatives");
      setRelatives(response.data);
    };
    fetchRelatives();
  };

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} mt="80px">
      <CardHeader bg="teal.700" borderBottom="1px solid white">
        <Text fontSize="xl" color="white" fontWeight="bold">
          {title}
        </Text>
      </CardHeader>
      <Table variant="simple" color={textColor}>
        <Thead bg="teal.700">
          <Tr>
            {captions.map((caption, idx) => (
              <Th color="white" key={idx} textAlign="left">
                {caption}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody bg="white">
          {relatives.map((relative) => (
            <RelativesTableRow
              key={relative._id}
              relative={relative}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ))}
        </Tbody>
      </Table>
      <ConfirmActionDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirmAction}
        action={currentAction}
      />

      <Modal onClose={onClose} size="3xl" isOpen={isOpen} closeOnOverlayClick={false} closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <RelativeDetailsForm onClose={onClose} m={10} relative={editingRelative} handleUpdate={handleUpdate} />
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default Relatives;
