// src/App.js
import { Box, Flex } from '@chakra-ui/react';
import Header  from './Header';
import Hero  from './HeroSection';
import  ServiceCards  from './Services';
import { InfoSection } from './InfoSection';
import  Footer  from './Footer';
import PromoSection from './PromoSection';
import { useRef } from 'react';

function Home2() {

  const learnMoreRef = useRef(null);

  return (
    <Flex direction={'column'} minH={'100vh'}>
      <Header />
      <Hero scrollToInfoSection={() => learnMoreRef.current.scrollIntoView({ behavior: "smooth" })}/>
      <InfoSection ref={learnMoreRef}/>
      <ServiceCards  />
      {/* <PromoSection /> */}
      <Footer />
    </Flex>
  );
}

export default Home2;
