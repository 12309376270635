import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import api from "../../api";
import { useNavigate, useParams } from "react-router-dom";

// Validation schema using Yup
const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password is too short - should be 8 characters minimum.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/\d/, "Password must contain at least one number.")
    .matches(
      /[-#!$@£%^&*()_+|~=`{}\[\]:";'<>?,.\/\\ ]/,
      "Password must contain at least one special character."
    )
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

function ResetPasswordForm() {
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const navigate = useNavigate();

  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { token } = useParams();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await api.post(`/api/user/reset-password/${token}`, {
        newPassword: values.password,
      });
      toast({
        title: "Password Reset Successful",
        description: "Your password has been successfully reset.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      console.log("Error resetting password :: ", error);
      toast({
        title: "Error Resetting Password",
        description: error.response.data.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setSubmitting(false);
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      mt="2rem"
      mb="30px"
    >
      <Text fontSize="4xl" mb={4} textColor="gray.500">
        Reset Password
      </Text>
      <Text fontSize="md" color="black" fontWeight="normal" mt="10px" mb="26px">
        Please enter your new password below to reset your password.
      </Text>

      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={ResetPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form>
                {/* Password Field */}
                <FormControl
                  isInvalid={errors.password && touched.password}
                  mb="36px"
                >
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    New Password
                  </FormLabel>
                  <InputGroup>
                    <Field
                      as={Input}
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      fontSize="sm"
                      ms="4px"
                      borderRadius="15px"
                      placeholder="Enter your new password"
                      size="lg"
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="Toggle Password Visibility"
                        h="1.75rem"
                        size="sm"
                        onClick={() => setShowPassword(!showPassword)}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <Text
                    fontSize="xs"
                    mt="1"
                    color="gray.400"
                    ml="10px"
                    mb="24px"
                  >
                    Your password should have:
                    <Text fontSize="xs" mt="1" color="gray.400" ml="20px">
                      <ul>
                        <li>At least 8 characters</li>
                        <li>At least one uppercase letter</li>
                        <li>At least one special character</li>
                        <li>At least one numeric digit</li>
                      </ul>
                    </Text>
                  </Text>
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                </FormControl>

                {/* Confirm Password Field */}
                <FormControl
                  isInvalid={errors.confirmPassword && touched.confirmPassword}
                  mb="36px"
                >
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Confirm New Password
                  </FormLabel>
                  <InputGroup>
                    <Field
                      as={Input}
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      fontSize="sm"
                      ms="4px"
                      borderRadius="15px"
                      placeholder="Confirm your new password"
                      size="lg"
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="Toggle Confirm Password Visibility"
                        h="1.75rem"
                        size="sm"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        icon={
                          showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />
                        }
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  bg="teal.300"
                  fontSize="10px"
                  color="white"
                  fontWeight="bold"
                  w="100%"
                  h="45"
                  mt="14px"
                  mb="24px"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.700",
                  }}
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  RESET PASSWORD
                </Button>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ResetPasswordForm;
