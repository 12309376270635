import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Link,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { TfiPulse } from "react-icons/tfi";
import { NavLink, useLocation } from "react-router-dom";
import { SidebarHelp } from "./SidebarHelp";
import logo from "../assets/img/logo.jpg";
import { useAuthContext } from "../hooks/useAuthContext";
import { userRoutes, adminRoutes } from "./routes";

function Sidebar() {
  let variantChange = "0.2s linear";

  let sidebarBg = useColorModeValue("gray.200", "gray.700");
  let sidebarRadius = "16px";
  let sidebarMargins = "16px 0px 16px 16px";
  const activeBg = useColorModeValue("white", "gray.700");
  const inactiveBg = useColorModeValue("white", "gray.700");
  const activeColor = useColorModeValue("gray.900", "white");
  const inactiveColor = useColorModeValue("gray.500", "gray.400");

  const { user } = useAuthContext();

  const firstName = user && user.name.split(" ")[0];

  let location = useLocation();

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavLink to={prop.layout + prop.path} key={prop.name}>
          {activeRoute(prop.layout + prop.path) === "active" ? (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg={activeBg}
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
              borderRadius="15px"
              _hover={{}}
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <Flex>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"12px"}
                    bg="teal.600"
                    color="white"
                    h="30px"
                    w="30px"
                    me="12px"
                  >
                    {prop.icon}
                  </Flex>
                )}
                <Text color={activeColor} my="auto" fontSize="sm">
                  {prop.name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg="transparent"
              mb={{
                xl: "12px",
              }}
              mx={{
                xl: "auto",
              }}
              py="12px"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              borderRadius="15px"
              _hover={{ bg: "teal.200" }}
              w="100%"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              <Flex>
                {typeof prop.icon === "string" ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"12px"}
                    bg={inactiveBg}
                    color="teal.300"
                    h="30px"
                    w="30px"
                    me="12px"
                  >
                    {prop.icon}
                  </Flex>
                )}
                <Text color={inactiveColor} my="auto" fontSize="sm">
                  {prop.name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      );
    });
  };

  const links = <>{createLinks(userRoutes)}</>;
  const adminLinks =
    user.role === "admin" ? <>{createLinks(adminRoutes)}</> : null;

  return (
    <Box display={{ sm: "none", xl: "block" }} position="fixed">
      <Flex
        justifyContent="space-between"
        direction="column"
        bg={sidebarBg}
        transition={variantChange}
        w="260px"
        maxW="260px"
        ms={{
          sm: "16px",
        }}
        my={{
          sm: "16px",
        }}
        h="calc(100vh - 32px)"
        ps="20px"
        pe="20px"
        m={sidebarMargins}
        borderRadius={sidebarRadius}
      >
        <Stack direction="column">
          <Box pt={"25px"} mb="12px">
            <Link
              href="/home"
              target="_blank"
              display="flex"
              lineHeight="100%"
              mb="30px"
              fontWeight="bold"
              justifyContent="center"
              alignItems="center"
              fontSize="11px"
            >
              <Image src={logo} alt="vKutumb Logo" height="40px" mr="10px" />
              {/* <Icon as={logo} w="32px" h="32px" me="10px" /> */}
              <Text fontSize="sm" mt="3px">
                Welcome {firstName}
              </Text>
            </Link>
            <Divider bgColor="teal.300" h="1px" w="100%" />
          </Box>
          <Box>{links}</Box>

          {adminLinks && (
            <Box>
              <Divider bgColor="teal.300" h="1px" w="100%" />
              <Flex
                py="12px"
                justify={"center"}
                // bg="teal"
                w="100%"
                mt="10px"
                mb="10px"
              >
                <Text fontWeight="bold" fontSize={"lg"} color="teal.700">
                  Admin Section
                </Text>
              </Flex>
              <Box>{adminLinks}</Box>
            </Box>
          )}
        </Stack>
        <Box mb="20px">
          <SidebarHelp />
        </Box>
      </Flex>
    </Box>
  );
}

export default Sidebar;
