import React from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Text,
  VStack,
  HStack
} from '@chakra-ui/react';

const CreateReportForm = ({ onSubmit }) => {
  const initialValues = {
    bookingId: '',
    issues: [''],
    primaryDiagnosis: '',
    secondaryDiagnosis: '',
    doctorComments: '',
    tests: [{ name: '', result: '', comments: '' }],
    medicines: [{ name: '', dosage: '', frequency: '', duration: '', comments: '' }],
    nextAppointment: '',
    specialInstructions: '',
    doctorContact: '',
    lifestyleChanges: '',
    preventiveMeasures: '',
    referrals: ''
  };

  const validationSchema = Yup.object().shape({
    bookingId: Yup.string().required('Booking Id is required'),
    issues: Yup.array().of(Yup.string().required('Issue is required')),
    primaryDiagnosis: Yup.string().required('Primary diagnosis is required'),
    doctorComments: Yup.string().required('Doctor comments are required'),
    tests: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Test name is required'),
        result: Yup.string().required('Test result is required'),
        comments: Yup.string().required('Test comments are required')
      })
    ),
    medicines: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Medicine name is required'),
        dosage: Yup.string().required('Dosage is required'),
        frequency: Yup.string().required('Frequency is required'),
        duration: Yup.string().required('Duration is required'),
        comments: Yup.string().required('Comments are required')
      })
    ),
    nextAppointment: Yup.string().required('Next appointment is required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        onSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <VStack spacing={4} align="flex-start">
            

            <FormControl>
              <FormLabel>Booking Id</FormLabel>
              <Field name="bookingId" as={Input} />
              <ErrorMessage name="bookingId" component={Text} color="red.500" />
            </FormControl>

            

            <FormControl>
              <FormLabel>Issues</FormLabel>
              <FieldArray
                name="issues"
                render={(arrayHelpers) => (
                  <VStack align="flex-start">
                    {values.issues.map((issue, index) => (
                      <HStack key={index}>
                        <Field name={`issues.${index}`} as={Input} placeholder="Issue" />
                        <Button type="button" onClick={() => arrayHelpers.remove(index)}>
                          -
                        </Button>
                        <Button type="button" onClick={() => arrayHelpers.insert(index + 1, '')}>
                          +
                        </Button>
                        <ErrorMessage name={`issues.${index}`} component={Text} color="red.500" />
                      </HStack>
                      
                    ))}
                    
                  </VStack>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Primary Diagnosis</FormLabel>
              <Field name="primaryDiagnosis" as={Input} />
              <ErrorMessage name="primaryDiagnosis" component={Text} color="red.500" />
            </FormControl>

            <FormControl>
              <FormLabel>Secondary Diagnosis</FormLabel>
              <Field name="secondaryDiagnosis" as={Input} />
              <ErrorMessage name="secondaryDiagnosis" component={Text} color="red.500" />
            </FormControl>

            <FormControl>
              <FormLabel>Doctor Comments</FormLabel>
              <Field name="doctorComments" as={Textarea} />
              <ErrorMessage name="doctorComments" component={Text} color="red.500" />
            </FormControl>

            <FormControl>
              <FormLabel>Tests</FormLabel>
              <FieldArray
                name="tests"
                render={(arrayHelpers) => (
                  <VStack align="flex-start">
                    {values.tests.map((test, index) => (
                      <Box key={index} borderWidth="1px" p={3} borderRadius="md" w="100%">
                        <Field name={`tests.${index}.name`} as={Input} placeholder="Test Name" />
                        <ErrorMessage name={`tests.${index}.name`} component={Text} color="red.500" />

                        <Field name={`tests.${index}.result`} as={Input} placeholder="Result" />
                        <ErrorMessage name={`tests.${index}.result`} component={Text} color="red.500" />

                        <Field name={`tests.${index}.comments`} as={Textarea} placeholder="Comments" />
                        <ErrorMessage name={`tests.${index}.comments`} component={Text} color="red.500" />

                        <Button type="button" onClick={() => arrayHelpers.remove(index)} mt={2}>
                          Remove Test
                        </Button>
                      </Box>
                    ))}
                    <Button type="button" onClick={() => arrayHelpers.push({ name: '', result: '', comments: '' })}>
                      Add Test
                    </Button>
                  </VStack>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Medicines</FormLabel>
              <FieldArray
                name="medicines"
                render={(arrayHelpers) => (
                  <VStack align="flex-start">
                    {values.medicines.map((medicine, index) => (
                      <Box key={index} borderWidth="1px" p={3} borderRadius="md" w="100%">
                        <Field name={`medicines.${index}.name`} as={Input} placeholder="Medicine Name" />
                        <ErrorMessage name={`medicines.${index}.name`} component={Text} color="red.500" />

                        <Field name={`medicines.${index}.dosage`} as={Input} placeholder="Dosage" />
                        <ErrorMessage name={`medicines.${index}.dosage`} component={Text} color="red.500" />

                        <Field name={`medicines.${index}.frequency`} as={Input} placeholder="Frequency" />
                        <ErrorMessage name={`medicines.${index}.frequency`} component={Text} color="red.500" />

                        <Field name={`medicines.${index}.duration`} as={Input} placeholder="Duration" />
                        <ErrorMessage name={`medicines.${index}.duration`} component={Text} color="red.500" />

                        <Field name={`medicines.${index}.comments`} as={Textarea} placeholder="Comments" />
                        <ErrorMessage name={`medicines.${index}.comments`} component={Text} color="red.500" />

                        <Button type="button" onClick={() => arrayHelpers.remove(index)} mt={2}>
                          Remove Medicine
                        </Button>
                      </Box>
                    ))}
                    <Button type="button" onClick={() => arrayHelpers.push({ name: '', dosage: '', frequency: '', duration: '', comments: '' })}>
                      Add Medicine
                    </Button>
                  </VStack>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Next Appointment</FormLabel>
              <Field name="nextAppointment" as={Input} type="datetime-local" />
              <ErrorMessage name="nextAppointment" component={Text} color="red.500" />
            </FormControl>

            <FormControl>
              <FormLabel>Special Instructions</FormLabel>
              <Field name="specialInstructions" as={Textarea} />
              <ErrorMessage name="specialInstructions" component={Text} color="red.500" />
            </FormControl>

            <FormControl>
              <FormLabel>Doctor Contact</FormLabel>
              <Field name="doctorContact" as={Input} />
              <ErrorMessage name="doctorContact" component={Text} color="red.500" />
            </FormControl>

            <FormControl>
              <FormLabel>Lifestyle Changes</FormLabel>
              <Field name="lifestyleChanges" as={Textarea} />
              <ErrorMessage name="lifestyleChanges" component={Text} color="red.500" />
            </FormControl>

            <FormControl>
              <FormLabel>Preventive Measures</FormLabel>
              <Field name="preventiveMeasures" as={Textarea} />
              <ErrorMessage name="preventiveMeasures" component={Text} color="red.500" />
            </FormControl>

            <FormControl>
              <FormLabel>Referrals</FormLabel>
              <Field name="referrals" as={Textarea} />
              <ErrorMessage name="referrals" component={Text} color="red.500" />
            </FormControl>

            <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
              Submit
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};

export default CreateReportForm;
