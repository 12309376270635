import React from "react";
import {
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepDescription,
  StepSeparator,
  Text,
  Box,
  StepIcon,
  StepNumber,
} from "@chakra-ui/react";

const BookVisitStepper = ({ activeStep, steps }) => {
  return (
    <Stepper
      index={activeStep}
      borderColor={"white.100"}
      borderWidth="1px"
      p="10px"
      shadow={"md"}
      colorScheme={"white"}
      w="100%"
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
          </StepIndicator>
          <Box flexShrink="1">
            <StepTitle fontSize={{ base: "sm", md: "md" }}>{step.title}</StepTitle>
            <StepDescription fontSize={{ base: "xs", md: "xs" }} color={"white"}>
              {step.description}
            </StepDescription>
          </Box>
          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};

export default BookVisitStepper;
