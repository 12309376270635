import React from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Button,
  FormErrorMessage,
  Text,
  Avatar,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import api from "../../api";
import { ArrowRightIcon, CloseIcon } from "@chakra-ui/icons";
import { TbClockCancel } from "react-icons/tb";
import CancelVisitDialog from "./CancelDialog";
import { useRouteLoaderData } from "react-router-dom";

const VisitForm = ({ booking, canEdit, handleClose, setActiveStep, bookingDataHandler }) => {
  const initialValues = {
    date: booking?.date || "",
    time: booking?.time || "",
    doctor: booking?.doctorName || "",
    address: booking?.doctorAddress || "",
    purpose: booking?.purpose || "",
  };

  const toast = useToast();

  // Calculate tomorrow's date
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0];
  // Calculate the date two weeks from today
  const twoWeeksFromToday = new Date(new Date().setDate(new Date().getDate() + 14)).toISOString().split("T")[0];

  const validationSchema = Yup.object({
    date: Yup.date()
      .min(tomorrow, "The date must be tomorrow or later")
      .max(twoWeeksFromToday, "Date must be within the next two weeks")
      .required("Date is required"),
    time: Yup.string()
      .required("Time is required")
      .test("is-valid-time", "Time must be between 8:00 AM and 10:00 PM", (value) => {
        if (!value) return false;
        const [hours, minutes] = value.split(":").map(Number);
        const timeInMinutes = hours * 60 + minutes;
        const minTimeInMinutes = 8 * 60; // 8:00 AM
        const maxTimeInMinutes = 22 * 60; // 10:00 PM
        return timeInMinutes >= minTimeInMinutes && timeInMinutes <= maxTimeInMinutes;
      }),
    doctor: Yup.string().required("Doctor's name is required"),
    address: Yup.string().required("Doctor's address is required"),
    //   purpose: Yup.string().required("Purpose of visit is required"),
  });

  let bgcolor = canEdit ? undefined : "gray.300";

  const { isOpen, onOpen, onClose } = useDisclosure();
  const relative = booking? booking.relative : bookingDataHandler.getCurrentRelative();

  const handleSubmit = async (values) => {
    console.log("Handle Submit invoked :: step => VisitForm", { booking, relative });
    // console.log("Visit Values :: ", values )
    try {
      let newStatus = "Pending Payment";
      if (booking && booking.status !== "Pending Payment") {
        if (values.date !== booking?.date || values.time !== booking?.time) {
          newStatus = "Rescheduled";
        } else {
          newStatus = "Booking Registered";
        }
      }

      var visitDetails = {
        doctorName: values.doctor,
        doctorAddress: values.address,
        purpose: values.purpose,
        date: values.date,
        time: values.time,
        relative: relative,
        status: newStatus,
        bookingId: booking?.bookingId,
      };
      await bookingDataHandler.addUpdateBooking(visitDetails);
    } catch (error) {
      toast({
        title: "Error booking Visit",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setActiveStep(1);
  };

  const handleCancelVisit = async () => {
    console.log("Cancel visit confirmed : ");

    try {
      bookingDataHandler.removeBooking(booking);
      onClose();
      handleClose();
    } catch (error) {
      toast({
        title: "Error cancelling visit",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const alertDialogData = {
    header: "Confirm Cancel Visit",
    body: "Are you sure you want to cancel this visit? You can't undo this action afterwards.",
    negationButtonText: "No",
    affirmationButtonText: "Yes",
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, errors, touched, isValid, dirty }) => (
        <Form>
          <VStack spacing={4} align="start">
            <Box width="100%">
              <HStack mt="10px" ml="20px" mb="10px" wrap={{ base: "wrap", md: "nowrap" }}>
                <Text fontSize="md" color="black" mr="20px">
                  Relative :
                </Text>
                <Avatar size="sm" name={relative.name} src={relative.image} bg="teal.400" />
                <Text fontSize="md" fontWeight={"bold"} wrap="wrap">
                  {relative.name}
                </Text>
                <Text fontSize="md" color={"gray.400"} fontWeight={"bold"} wrap="wrap">
                  ( {relative.relation} )
                </Text>
              </HStack>
            </Box>
            <HStack w="100%" wrap={{ base: "wrap", md: "nowrap" }}>
              <FormControl id="date" isInvalid={touched.date && errors.date}>
                <FormLabel>Date</FormLabel>
                <Field
                  as={Input}
                  type="date"
                  name="date"
                  min={tomorrow}
                  max={twoWeeksFromToday}
                  isReadOnly={!canEdit}
                  bgColor={bgcolor}
                />
                <FormErrorMessage>{errors.date}</FormErrorMessage>
              </FormControl>
              <FormControl id="time" isInvalid={touched.time && errors.time}>
                <FormLabel>Time</FormLabel>
                <Field
                  as={Input}
                  type="time"
                  name="time"
                  min="08:00"
                  max="22:00"
                  isReadOnly={!canEdit}
                  bgColor={bgcolor}
                />
                <FormErrorMessage>{errors.time}</FormErrorMessage>
              </FormControl>
            </HStack>
            <FormControl id="doctor" isInvalid={touched.doctor && errors.doctor}>
              <FormLabel>Doctor's Name</FormLabel>
              <Field as={Input} type="text" name="doctor" isReadOnly={!canEdit} bgColor={bgcolor} />
              <FormErrorMessage>{errors.doctor}</FormErrorMessage>
            </FormControl>
            <FormControl id="address" isInvalid={touched.address && errors.address}>
              <FormLabel>Doctor's Address</FormLabel>
              <Field as={Input} type="text" name="address" isReadOnly={!canEdit} bgColor={bgcolor} />
              <FormErrorMessage>{errors.address}</FormErrorMessage>
            </FormControl>
            <FormControl id="purpose" isInvalid={touched.purpose && errors.purpose}>
              <FormLabel>Purpose of Visit</FormLabel>
              <Field as={Input} type="text" name="purpose" isReadOnly={!canEdit} bgColor={bgcolor} />
              <FormErrorMessage>{errors.purpose}</FormErrorMessage>
            </FormControl>
          </VStack>
          <HStack justify="space-between" mt={6}>
            {/* <Button
            leftIcon={<CloseIcon />}
            onClick={() => handleClose()}
            mr={2}
            variant="outline"
            colorScheme={"red"}
          >
            Close
          </Button>
          <Spacer /> */}
            <Button
              rightIcon={<TbClockCancel fontSize="1.5rem" />}
              onClick={() => onOpen()}
              colorScheme="red"
              variant="outline"
            >
              Cancel Visit
            </Button>
            <Button rightIcon={<ArrowRightIcon />} type="submit" colorScheme="teal" isDisabled={!isValid || !dirty}>
              {booking ? "Update Visit" : "Book vMitra"}
            </Button>
          </HStack>
          <CancelVisitDialog
            isOpen={isOpen}
            onNegation={onClose}
            onAffirmation={handleCancelVisit}
            alertDialogData={alertDialogData}
          />
        </Form>
      )}
    </Formik>
  );
};

export default VisitForm;
