import axios from 'axios';

const baseURL = process.env.NODE_ENV === "production" ? "/" : "http://localhost:4000/"

const api = axios.create({
  baseURL,
  // baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4000/',
});

api.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('user'));
  if (token) {
    config.headers.Authorization = `Bearer ${token.token}`;
  }
  return config;
});

export default api;
