import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Spinner,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import api from "../../api";
import CancelVisitDialog from "../Visit/CancelDialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Step 1: Add custom fonts
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

const EditBlog = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [blogToEdit, setBlogToEdit] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [heroImage, setHeroImage] = useState(null);
  const [previewHeroImage, setPreviewHeroImage] = useState(null);

  const [dialogData, setDialogData] = useState(null);

  const reactQuillRef = useRef(null);

  const navigate = useNavigate(); // Initialize the navigation hook

  useEffect(() => {
    if(id){
      api.get(`/api/blogs/${id}`).then((response) => {
        const blog = response.data;
        if (blog) {
          setBlogToEdit(blog);
          setTitle(blog.title);
          setContent(blog.content);
          setPreviewHeroImage(blog.heroImage);
        }
        setLoading (false);
      });
    } else {
      setLoading(false);
    }
    
  }, []);

  // Handle hero image upload
  const handleHeroImageChange = (e) => {
    const file = e.target.files[0];
    setHeroImage(file);
    setPreviewHeroImage(URL.createObjectURL(file)); // Preview image
  };

  // Handle image upload to the server for React Quill
  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("image", file);

      // Upload image to the server
      try {
        const res = await api.post("/api/upload/blog-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Insert the uploaded image into the editor
        const quill = reactQuillRef.current.getEditor();
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", "/api" + res.data.imageUrl);
      } catch (err) {
        console.error("Failed to upload image", err);
      }
    };
  };

  const handleSubmit = async () => {
    if (!title || !content) {
      alert("Title and content are required.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    if (heroImage) formData.append("heroImage", heroImage);

    try {
      if (blogToEdit) {
        const res = await api.put(`/api/blogs/${blogToEdit._id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        const res = await api.post("/api/blogs", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("response.data :: ", res.data.blog);
        setBlogToEdit(res.data.blog);
      }
      setDialogData({
        header: "Blog Posted",
        body: (
          <Text>
            Your blog{" "}
            <Text as="span" color="teal.500" fontWeight={"bold"}>
              {title}
            </Text>{" "}
            has been posted successfully!
          </Text>
        ),
        affirmationButtonText: "Ok",
        affirmButtonColor: "teal",
        headerBgColor: "teal.700",
      });
      onOpen();
    } catch (error) {
      console.log(error);
      setDialogData({
        header: "Error: Failed to create blog : ",
        body: error.response.data.error
          ? error.response.data.error
          : error.response.data,
        affirmationButtonText: "Ok",
        affirmButtonColor: "red",
        headerBgColor: "red.700",
      });
      onOpen();
    }
  };

  const handlePublish = () => {
    try {
      api.put(`/api/blogs/${blogToEdit._id}/publish`);
      setDialogData({
        header: "Blog Published",
        body: (
          <Text>
            Your blog{" "}
            <Text as="span" color="teal.500" fontWeight={"bold"}>
              {title}
            </Text>{" "}
            has been published successfully! It is now available for everyone to
            read.
          </Text>
        ),
        affirmationButtonText: "Ok",
        affirmButtonColor: "teal",
        headerBgColor: "teal.700",
      });
      onOpen();
    } catch (error) {
      console.log(error);
      setDialogData({
        header: "Error: Failed to publish blog : ",
        body: error.response.data.error
          ? error.response.data.error
          : error.response.data,
        affirmationButtonText: "Ok",
        affirmButtonColor: "red",
        headerBgColor: "red.700",
      });
      onOpen();
    }
  };

  const handleOk = () => {
    if (dialogData.affirmButtonColor === "teal") {
      setTitle("");
      setContent("");
      setPreviewHeroImage(null);
      setHeroImage(null);
      navigate(`/blogs/${blogToEdit.slug}`);
    }

    onClose();
  };

  const quillModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [
            { "header": "1" },
            { "header": "2" },
            { "header": "3" },
            { "font": Font.whitelist },
          ],
          [{ "list": "ordered" }, { "list": "bullet" }],
          ["bold", "italic", "underline", "blockquote"],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  if(loading) return <Spinner />

  return (
    <Flex mt="80px" flexDir="column" gap={4}>
      <FormLabel >Blog Title :</FormLabel>
      <Input
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <FormLabel >Hero Image :</FormLabel>
      <Input
        type="file"
        onChange={handleHeroImageChange}
        placeholder="Hero Image"
      />
      {previewHeroImage && (
        <Box mt={2}>
          <FormLabel >Hero Image Preview :</FormLabel>
          <Image src={previewHeroImage} alt="Hero Image Preview" maxH="300px" />
        </Box>
      )}
      <Box>
        <ReactQuill
          ref={reactQuillRef}
          value={content}
          onChange={setContent}
          modules={quillModules}
          theme="snow"
        />
      </Box>
      <HStack justify={"right"} mt={4}>
        <Button onClick={handleSubmit} colorScheme={"teal"}>
          {blogToEdit ? "Update Blog" : "Create Blog"}
        </Button>
        {blogToEdit && (
          <Button colorScheme="blue" onClick={handlePublish}>
            Publish Blog
          </Button>
        )}
      </HStack>
      {isOpen && dialogData && (
        <CancelVisitDialog
          isOpen={isOpen}
          onAffirmation={handleOk}
          alertDialogData={dialogData}
        />
      )}
    </Flex>
  );
};

export default EditBlog;
