import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
import { useNavigate } from "react-router-dom";
import api from '../api'


export const useLogin = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { setUser } = useAuthContext()
  const navigate = useNavigate();


  const login = async (creds) => {
    setIsLoading(true)
    setError(null)

    try{
      const response = await api.post('/api/user/login', creds)
      // save the user to local storage
      localStorage.setItem('user', JSON.stringify(response.data))

      // update the auth context
      setUser( response.data)

      // update loading state
      setIsLoading(false)
      navigate("/user/dashboard")
      
    } catch (error) {
      setIsLoading(false)
      setError(error)
      return error
    }
  }


  const signup = async (creds) => {
    setIsLoading(true)
    setError(null)

    try{
      const response = await api.post('/api/user/signup', creds)
      // save the user to local storage
      localStorage.setItem('user', JSON.stringify(response.data))

      // update the auth context
      setUser(response.data)

      // update loading state
      setIsLoading(false)
      navigate("/user/dashboard")
    } catch (error) {
      setIsLoading(false)
      setError(error)
      return error

    }
  }

  return { login, signup, isLoading, error }
}