import { createContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
