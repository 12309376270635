import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
  Spinner,
  Center,
  Button,
} from '@chakra-ui/react';
import api from "../api";
import { json2csv } from 'json-2-csv';

const WebsiteVisitorTable = () => {
  const [visitors, setVisitors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the visitor data from the backend
    const fetchData = async () => {
      try {
        const response = await api.get('/api/visitors/all'); // Make sure this route exists on your backend
        setVisitors(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching visitors:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const downloadCSV = async () => {
    try {
      // Convert the JSON data to CSV format using json-2-csv
      const csv = await json2csv(visitors);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'visitors.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating CSV:', error);
    }
  };


  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }
// country, regionName, city, zip, timezone, mobile, proxy, isp
  return (
    <Box p={5} mt="120px">
      <Button colorScheme="teal" onClick={downloadCSV} mb={4}>
        Download CSV
      </Button>
      <TableContainer>
        <Table variant="striped" colorScheme="teal">
          <TableCaption>Visitor Data</TableCaption>
          <Thead>
            <Tr>
              <Th>IP Address</Th>
              <Th>Visit Count</Th>
              <Th>Last Visited</Th>
              <Th>Country</Th>
              <Th>Region</Th>
              <Th>City</Th>
              <Th>zip</Th>
              <Th>Is Mobile</Th>
              <Th>ISP</Th>
            </Tr>
          </Thead>
          <Tbody>
            {visitors.map((visitor, index) => (
              <Tr key={index}>
                <Td>{visitor.ipAddress}</Td>
                <Td>{visitor.visitCount}</Td>
                <Td>{new Date(visitor.lastVisited).toLocaleString()}</Td>
                <Td>{visitor.geolocation?.country || 'N/A'}</Td>
                <Td>{visitor.geolocation?.regionName || 'N/A'}</Td>
                <Td>{visitor.geolocation?.city || 'N/A'}</Td>
                <Td>{visitor.geolocation?.zip || 'N/A'}</Td>
                <Td>{visitor.geolocation?.mobile || 'N/A'}</Td>
                <Td>{visitor.geolocation?.isp || 'N/A'}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default WebsiteVisitorTable;
