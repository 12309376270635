// Custom icons
import { AdobexdLogo, AtlassianLogo, InvisionLogo, JiraLogo, SlackLogo, SpotifyLogo } from "../../components/Icons.js";
import { AiOutlineExclamation } from "react-icons/ai";
import { FaArrowDown, FaArrowUp, FaBell, FaCreditCard, FaHtml5, FaShoppingCart } from "react-icons/fa";
import { FaPersonArrowDownToLine, FaPersonCircleCheck, FaPeopleRoof, FaFileLines } from "react-icons/fa6";
import { TfiWrite } from "react-icons/tfi";

import { SiDropbox } from "react-icons/si";

export const timelineData = [
  {
    logo: FaFileLines,
    title: "Visit Scheduled",
    color: "blue",
  },
  {
    logo: FaCreditCard,
    title: "Payment done",
    color: "teal.300",
  },
  {
    logo: FaPersonCircleCheck,
    title: "Help Person Assigned",
    color: "purple",
  },
  {
    logo: FaPersonArrowDownToLine,
    title: "Help Person arrives",
    color: "blue.400",
  },
  {
    logo: FaPeopleRoof,
    title: "Takes to Hospital/Clinic",
    color: "orange",
  },

  {
    logo: TfiWrite,
    title: "Detailed Reports shared",
    color: "orange.300",
  },
];
