import { AddIcon } from "@chakra-ui/icons";
import {
  AbsoluteCenter,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import ChakraInput from "../../components/ChInput";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import api from "../../api";
import { useNavigate } from "react-router-dom";

function RelativeDetailsForm(props) {
  //TODO Relative is just temporary addition - will check if we need it or should remove later
  const { bookingDataHandler, relative, handleUpdate } = props;
  const navigate = useNavigate();
  const initialValues = {
    ...relative,
    name: relative?.name || "",
    relation: relative?.relation || "",
    gender: relative?.gender || "",
    age: relative?.age || "",
    address: relative?.address || "",
    phoneNumber: relative?.phoneNumber || "",
  };

  async function onSubmit(values, submitProps) {
    if (relative) {
      values._id = relative._id;
      console.log("Original relative :: ", relative);
      console.log("Relative update :: ", values);
      handleUpdate(values);
      return;
    }

    // console.log("Form data", values);
    // console.log("submitProps", submitProps);
    var addedRel = await addRelativeAPI(values);
    bookingDataHandler.addRelativeToLocal(addedRel);
    submitProps.setSubmitting(false);
    submitProps.resetForm();

    navigate("/user/dashboard");
    if (props.onClose) props.onClose();
  }

  const addRelativeAPI = async (relativeDetails) => {
    try {
      const res = await api.post("/api/relatives", relativeDetails, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      console.log("Relative added :: ", res.data);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    // relation: Yup.string().required("Required"),
    address: Yup.string().required("Address is required"),
    phoneNumber: Yup.string().required("Phone number is Required"),
    gender: Yup.string().required("Gender is required"),
    age: Yup.number()
      .required("Age is required")
      .min(1, "Age must be a positive number")
      .max(150, "Age should be less than 150"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values, errors, touched }) => {
        console.log("Form values:", values); // Add this line
        return (
          <Form>
            <Card boxShadow="sm" ml={props.ml} mr={props.mr} m={props.m}>
              <CardBody>
                <Text fontSize="lg" color="gray.700" fontWeight="bold" pb=".5rem">
                  Add relative
                </Text>
                <Box position="relative" padding="2" borderColor="teal" mb="35" mt="1">
                  <Divider colorScheme="teal" size="xl" />
                  <AbsoluteCenter bg="white" px="4">
                    <Text fontSize={{ md: "md", sm: "sm" }} color="gray.500" fontWeight="normal">
                      Add a relative to book
                    </Text>
                  </AbsoluteCenter>
                </Box>

                <Grid
                  gridTemplateRows={{ sm: "repeat(5, 1fr)", md: "repeat(5, 1fr)", lg: "repeat(4, 1fr)" }}
                  gridTemplateColumns={"repeat(2, 1fr)"}
                  gap="2"
                  color="blackAlpha.700"
                  fontWeight="bold"
                >
                  <GridItem pl="2" colSpan={{ sm: "2", md: "2", lg: "1" }}>
                    <ChakraInput 
                    placeholder="Relative Name" 
                    type="text" name="name" 
                    label="Relative Name" 
                    />
                  </GridItem>
                  <GridItem pl="2" colSpan={{ sm: "2", md: "2", lg: "1" }}>
                    <ChakraInput placeholder="Relation" type="text" name="relation" label="Relation" />
                  </GridItem>
                  <GridItem pl="2" colSpan={{ sm: "2", md: "2", lg: "1" }}>
                    <ChakraInput placeholder="Age" type="number" name="age" label="Relative's Age" />
                  </GridItem>
                  <GridItem pl="4" colSpan={{ sm: "2", md: "2", lg: "1" }}>
                    {/* Gender */}

                    <FormControl isInvalid={errors.gender && touched.gender} isRequired>
                      <FormLabel htmlFor="gender">Gender</FormLabel>
                      <Field name="gender">
                        {({ field, form }) => (
                          <RadioGroup
                            {...field}
                            id="gender"
                            onChange={(value) => form.setFieldValue(field.name, value)}
                          >
                            <Stack direction="row">
                              <Radio name="gender" value="Male">Male</Radio>
                              <Radio name="gender" value="Female">Female</Radio>
                            </Stack>
                          </RadioGroup>
                        )}
                      </Field>
                      
                      <FormErrorMessage>{errors.gender}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem pl="2" colSpan="2">
                    <ChakraInput
                      as="phoneinput"
                      placeholder="Relative's Phone Number"
                      type="text"
                      name="phoneNumber"
                      label="Relative's Phone Number"
                    />
                  </GridItem>
                  <GridItem pl="2" colSpan="2">
                    <ChakraInput as="textarea" placeholder="Address" type="text" name="address" label="Address" />
                  </GridItem>
                </Grid>
              </CardBody>
              <CardFooter justify={"center"}>
                {props.onClose && (
                  <Button onClick={props.onClose} mr={2}>
                    Cancel
                  </Button>
                )}
                <Button type="submit" variant="solid" bg="teal.500" textColor="white">
                  <AddIcon boxSize={5} mr={2} color="white" ml={2} />
                  <Text mr={2}>{relative ? "Update" : "Add"}</Text>
                </Button>
              </CardFooter>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default RelativeDetailsForm;
