import React from "react";
import { Box, ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import Sidebar from "../components/Sidebar";
import MainPanel from "../components/MainPanel";
import AdminNavbar from "../components/AdminNavbar";
import { Outlet, Route, Routes } from "react-router-dom";
import PanelContent from "../components/PanelContent";
import PanelContainer from "../components/PanelContainer";
import theme from "../theme/theme.js";


function MainLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <ChakraProvider theme={theme}  resetCss={false}>
      <Sidebar />
      <MainPanel
        w={{
          base: "100%",
          xl: "calc(100% - 275px)",
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
          />
        </Portal>
        <PanelContent>
          <PanelContainer>
            {/* <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
              </Routes> */}
              <Outlet />
          </PanelContainer>
        </PanelContent>
      </MainPanel>
    </ChakraProvider>
  );
}

export default MainLayout;
