import { Box, Flex, Grid, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import RelativeCard from "./RelativeCard";
import RecentVisits from "../Visit/RecentVisits";
import { timelineData } from "./general";
import VisitProcessOverview from "./VisitProcessOverview";
import RelativeDetailsForm from "../Relatives/RelativeDetailsModal";
import { useRevalidator } from "react-router-dom";
import BookingDataHandler from "../Visit/BookingDataHandler";
import VisitOverview from "./VisitOverview";
import Milestones from "./VisitOverview";

function Dashboard() {
  let revalidator = useRevalidator();

  let [relatives, setRelatives] = useState([]);
  let [bookings, setBookings] = useState(null);
  let [bookingDataHandler, setBookingDataHandler] = useState(null);

  useEffect(() => {
    async function BookingDataHandlerInvoke() {
      const bookingDataHandler = new BookingDataHandler(
        revalidator,
        setBookings,
        setRelatives
      );

      await bookingDataHandler.loadRelativeData()

      console.log("Got BookingDataHandler",bookingDataHandler);
      setBookingDataHandler(bookingDataHandler);
      
      console.log("Set done for BookingDataHandler");
    }
    BookingDataHandlerInvoke();
    

  }, []);

  if (!bookingDataHandler || !bookings) {
    return (
      <Box textAlign="center" mt="8">
        <Flex direction="column" align="center" justify="center" height="500px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          <Text mt="4" fontSize="lg">
            Loading data, please wait...
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      {relatives && relatives.length > 0 ? (
        <Flex mb={5} mt={5} justify={"space-around"} wrap="wrap" gap={4}>
          {relatives.map((item, index) => (
            <RelativeCard
              key={item._id}
              {...item}
              relative={item}
              bookingDataHandler={bookingDataHandler}
            />
          ))}
          <RelativeCard
            key="addNew"
            newRel={true}
            bookingDataHandler={bookingDataHandler}
          />
        </Flex>
      ) : (
        <Grid
          templateColumns={{ md: "1fr", lg: "1fr" }}
          templateRows={{ md: "1fr", lg: "1fr" }}
          my="26px"
          gap="24px"
          justify="center"
        >
          <RelativeDetailsForm
            ml={{ lg: "30%", sm: "0%" }}
            mr={{ lg: "30%", sm: "0%" }}
            revalidator={revalidator}
            addRelativeFn={bookingDataHandler.addRelativeToLocal}
          />
        </Grid>
      )}
      <Grid
        templateColumns={{ sm: "1fr", md: "2fr 1fr", lg: "3fr 1fr" }}
        templateRows={{ sm: "1fr", md: "1fr", lg: "1fr" }}
        gap="24px"
      >
        <RecentVisits bookingDataHandler={bookingDataHandler} />
        <VisitProcessOverview title={"vKutumb Process At a glance"} amount={30} data={timelineData} />
        {/* <Milestones /> */}
      </Grid>
    </Flex>
  );
}

export default Dashboard;
