import React, { useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Collapse,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stat,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { BsPersonFillAdd } from "react-icons/bs";
// import { FaPersonCirclePlus } from "react-icons/fa6";
import RelativeDetailsForm from "../Relatives/RelativeDetailsModal";
import BookVisit from "../Visit/VisitRoot";

function RelativeCard(props) {
  let { name, relation, image, newRel, bookingDataHandler } = props;
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newVisitOpen, setNewVisitOpen] = useState(false);

  const handleClick = () => {
    onOpen();
  };

  const handleClickToBook=()=>{
    bookingDataHandler.setSelectedRelative(props.relative);
    setNewVisitOpen(true);
  }

  var avatar;
  var buttonText;
  if (newRel) {
    name = "Add New Relative";
    avatar = <Avatar size="xl" color="white" border="2px" bg="blue.300" icon={<BsPersonFillAdd fontSize="3.5rem" />} />;
    buttonText = "Add";
  }
  return (
    <Card
      cursor={newRel ? "pointer" : "default"} // Set cursor based on newRel
      onClick={newRel ? handleClick : undefined}
      minH="180px"
      boxShadow="sm"
      _hover={{ boxShadow: "lg" }}
      w="300px"
    >
      <CardBody border="1px" borderColor="gray.200">
        <Flex flexDirection="row" align="center" justify="center" w="100%">
          {newRel ? avatar : <Avatar size="xl" name={name} src={image} bg="teal.600" textColor={"white"} />}
          <Stat me="auto" ml={4}>
            <Text fontSize="sm" color="gray.400" fontWeight="bold">
              {relation}
            </Text>
            <Text fontSize="md" color={textColor} fontWeight={"bold"} wrap="wrap">
              {name}
            </Text>
          </Stat>
        </Flex>
        <Box variant="outline" align="right" mt={2}>
          <Button variant={newRel? "solid" : "outline"} colorScheme={newRel? "blue" : "teal"} onClick={newRel ? undefined : handleClickToBook}>
            {newRel ? buttonText : "Click to Book"}
          </Button>
        </Box>
      </CardBody>
      {newRel ? (
        <Modal onClose={onClose} size="3xl" isOpen={isOpen} closeOnOverlayClick={false} closeOnEsc={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <RelativeDetailsForm onClose={onClose} m={10} bookingDataHandler={bookingDataHandler} />
          </ModalContent>
        </Modal>
      ) : (
        <BookVisit
          onClose={() => setNewVisitOpen(false)}
          detailsOpen={newVisitOpen}
          bookingDataHandler={bookingDataHandler}
        />
      )}
    </Card>
  );
}

export default RelativeCard;
