// VerifyOTP.js
import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import api from "../../api";

const VerifyOTP = ({ email, setVerified }) => {
  const toast = useToast();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    // Call backend API to verify OTP
    try {
      await api.post("/api/user/verifyResetOTP", email, values.otp);
      toast({
        title: 'OTP Verified',
        description: 'OTP is valid. Proceed to reset your password.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setVerified(true);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Invalid OTP. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setSubmitting(false);
  };

  return (
    <Flex direction="column" align="center" justify="center" mt="8rem">
      <Box bg="white" p={6} rounded="md" shadow="md">
        <Formik
          initialValues={{ otp: '' }}
          validationSchema={Yup.object({
            otp: Yup.string().length(6, 'OTP must be 6 digits').required('Required'),
          })}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormControl>
                <FormLabel>Enter OTP</FormLabel>
                <Field name="otp" as={Input} type="text" placeholder="Enter OTP sent to your email" />
                <FormErrorMessage>{}</FormErrorMessage>
              </FormControl>
              <Button
                mt={4}
                type="submit"
                isLoading={isSubmitting}
                colorScheme="teal"
              >
                Verify OTP
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default VerifyOTP;
