import React from "react";
import "./404.css";
import { Card , Flex } from "@chakra-ui/react";

function F404() {
  return (
    <Card mt="120">
      <section class="page_404">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 ">
              <div class="col-sm-10 col-sm-offset-1  text-center">
                <div class="four_zero_four_bg">
                  <h1 class="text-center ">404</h1>
                </div>

                <Flex alignItems="center" direction={"column"}>
                  <h3 class="h2">Look like you're lost</h3>

                  <p>the page you are looking for not avaible!</p>

                  <a href="/home" class="link_404">
                    Go to Home
                  </a>
                </Flex>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Card>
  );
}

export default F404;
